import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import "./styles/FeatureCardModern.css";
import { useAppSelector } from "../app/hooks";
import { FeaturesModernCardData } from "../data/LandingPageData";
const FeatureCardModern: React.FC<{
  title: string;
  img: string;
  text: string;
}> = (props) => {
  let screenType: "PC" | "Tab" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });
  if (widthObj.width > 1100) screenType = "PC";
  else if (widthObj.width > 600) screenType = "Tab";
  else screenType = "Mobile";

  let cardNumber = null;
  cardNumber = 12 / FeaturesModernCardData.body.length;

  return (
    <>
      <IonCol
        size={screenType === "PC" ? "3" : screenType === "Tab" ? "6" : "12"}
        className="feature_card_col"
      >
        <IonCard className="feature_card">
          <div className="feature_card_img">
            <img alt="Silhouette of mountains" src={props.img} />
          </div>

          <IonCardHeader className="feature_card_p_box">
            <IonCardTitle className="feature_card_p_h1">
              {props.title}
            </IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent className="feature_card_p">
            {props.text}
          </IonCardContent>
        </IonCard>
      </IonCol>
    </>
  );
};

export default FeatureCardModern;
