import { IonCard } from "@ionic/react";
import React from "react";
import "./styles/Card.css";

const Card: React.FC<{
  children: React.ReactNode;
}> = (props) => {
  return <IonCard className="card-style">{props.children}</IonCard>;
};

export default Card;
