import React, { useEffect, useRef } from "react";
import { useAppSelector } from "../app/hooks";
import { aboutUs, NavLinkRefType } from "../data/data";
import SubHeader from "../UI/SubHeader";
import "./styles/AboutUs.css";

const AboutUs: React.FC<{ navRef?: NavLinkRefType }> = (props) => {
  const ref = useRef();
  let screenType: "PC" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });

  if (widthObj.width > 850) screenType = "PC";
  else screenType = "Mobile";

  useEffect(() => {
    if (ref && props.navRef) props.navRef.ref.current[props.navRef.id] = ref;
  }, [ref]);

  const flexCss =
    screenType === "Mobile" ? "aboutus-wrapper-mob" : "aboutus-wrapper";

  const fontCss = screenType === "PC" ? "about-us-font" : "";

  return (
    <div className="aboutus-top" ref={ref}>
      {/* <SubHeader text="About Us" enableStickyScroll={false} /> */}
      <div className={flexCss}>
        <div
          className={
            screenType === "Mobile"
              ? "aboutus-imp-wrpr-mob"
              : "aboutus-imp-wrpr"
          }
        >
          <img
            src="assets/images/iDigizen_Logo.png"
            alt=""
            className={
              screenType === "Mobile" ? "aboutus-img-mob" : "aboutus-img"
            }
          />
        </div>
        <div className={`aboutus-text ${fontCss}`}>
          <div className="aboutus-para-wrpr">
            {aboutUs.map((el, idx) => {
              return (
                <div className="about-us-para" key={idx}>
                  {el}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
