import { IonHeader, IonButton, IonPage, IonContent } from "@ionic/react";
import "./styles/LandingStoreBanner.css";
import { arrowForwardCircle, logoWhatsapp } from "ionicons/icons";
import { IonIcon } from "@ionic/react";

// import { LandingStoreBannerData } from "../data/LandingPageData";
import { useAppSelector } from "../app/hooks";
const LandingStoreBanner: React.FC<{
  headerSubtitle: string[];
  headerTitle: string;
  headerButton: any;
  MobImage: string;
  WebImage: string;
}> = (props) => {
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });
  const isPc = widthObj.width > 700;
  const displaySubtextJsx = (
    <>
      {props.headerSubtitle.map((el) => {
        return (
          <ul>
            <li> {el}</li>
          </ul>
        );
      })}
    </>
  );

  const pcJsx = (
    <div className="store_landing_banner">
      <div className="store_landing_banner-left">
        <div className="store_landing_banner-text">
          <h1 dangerouslySetInnerHTML={{ __html: props.headerTitle }} />
          {displaySubtextJsx}
          {props.headerButton}
        </div>
      </div>

      <div className="store_landing_banner-right">
        <div className="store_landing_banner-image1">
          <img src={props.WebImage} alt="" />
        </div>
        <div className="store_landing_banner-image2">
          <img src={props.MobImage} alt="" />
        </div>
      </div>
    </div>
  );

  const mobJsx = (
    <div className="store_landing_banner-mob">
      <div className="store_landing_banner-left-mob">
        <div className="store_landing_banner-text">
          <h1 dangerouslySetInnerHTML={{ __html: props.headerTitle }} />
          {displaySubtextJsx}
          {props.headerButton}
        </div>
      </div>

      <div className="store_landing_banner-right-mob">
        <div className="store_landing_banner-image1-mob">
          <img src={props.WebImage} alt="" />
        </div>
        <div className="store_landing_banner-image2-mob">
          <img src={props.MobImage} alt="" />
        </div>
      </div>
    </div>
  );
  return (
    <>
      {isPc && pcJsx}
      {!isPc && mobJsx}
    </>
  );
};

export default LandingStoreBanner;
