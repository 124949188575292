export { getParamsFromSearchString, keyExists };

const getParamsFromSearchString = (str: string): any => {
  const urlSearchParams = new URLSearchParams(str);
  return Object.fromEntries(urlSearchParams.entries());
};

const keyExists = (key: string, obj: { [key: string]: any }) => {
  if (obj === undefined) return false;
  let isExists = key in obj;
  if (!isExists || obj[key] === undefined || obj[key] === null) return false;
  return true;
};
