import { IonButton } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { useAppSelector } from "../app/hooks";
import { NavLinkRefType, pricingText } from "../data/data";
import SubHeader from "../UI/SubHeader";
import FormButton from "./FormButton";
import "./styles/Pricing.css";

const Pricing: React.FC<{ navRef: NavLinkRefType }> = (props) => {
  const ref = useRef();

  useEffect(() => {
    if (ref) props.navRef.ref.current[props.navRef.id] = ref;
  }, [ref]);

  let screenType: "PC" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });

  if (widthObj.width > 850) screenType = "PC";
  else screenType = "Mobile";

  const pricingTopCss = screenType === "PC" ? "pricing-top" : "pricing-top-mob";
  const pricingTextPCJsx = (
    <>
      <div>India's</div>
      <div>First</div>
      <div>Pay-As-You-Go</div>
      <div>ERP</div>
    </>
  );
  const pricingTextMobJsx = <>India's First Pay-As-You-Go ERP</>;

  const pcTextLayoutJsx = (
    <>
      <div className="pricing-text">
        <div>{pricingTextPCJsx}</div>
      </div>
      <div className="pricing-features">
        <div className="pricing-feature-card-title">{pricingText.title}</div>
        <div className="pricing-feature-card-body">
          {/* <div className="pricing-feature-list-hdr">
                {pricingText.subtitle}
              </div> */}
          <div className="pricing-feature-list">
            {pricingText.features.map((el, idx) => {
              return (
                <div className="pricing-feature-el" key={idx}>
                  {el}
                </div>
              );
            })}
          </div>
        </div>
        <div className="pricing-button">
          <FormButton name="Know More" />
        </div>
      </div>
    </>
  );

  const mobTextLayoutJsx = (
    <div className="pricing-text-mob">
      <div>{pricingTextMobJsx}</div>
      <div className="pricing-features-mob">
        <div className="pricing-feature-card-title-mob">
          {pricingText.title}
        </div>
        <div className="pricing-feature-card-body">
          {/* <div className="pricing-feature-list-hdr">
                {pricingText.subtitle}
              </div> */}
          <div className="pricing-feature-list">
            {pricingText.features.map((el, idx) => {
              return (
                <div className="pricing-feature-el-mob" key={idx}>
                  {el}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="pricing-top-wrpr" ref={ref}>
      <div className="navlink-class" id="pricing-top-id"></div>
      <SubHeader text="Pricing" enableStickyScroll={screenType === "Mobile"} />
      <div className="pricing-bg">
        <div className={pricingTopCss}>
          {screenType === "PC" && pcTextLayoutJsx}
          {screenType === "Mobile" && mobTextLayoutJsx}
          <div
            className={
              screenType === "Mobile"
                ? "pricing-img-wrp-mob"
                : "pricing-img-wrp"
            }
          >
            <img
              src="assets/images/pricing.png"
              className={
                screenType === "PC" ? "pricing-img" : "pricing-img-mob"
              }
            />
          </div>
        </div>
      </div>
      {screenType === "Mobile" && (
        <div className="pricing-button-mob">
          <FormButton name="Know More" />
        </div>
      )}
    </div>
  );
};

export default Pricing;
