import { AppDispatch, RootState } from "../store";
import { TypedUseSelectorHook } from "react-redux";
import { useSelector, useDispatch } from "react-redux";
import { useIonToast } from "@ionic/react";
import { AxiosError } from "axios";
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useCustomToast = () => {
  const [present] = useIonToast();
  const presentToast = (e: AxiosError): void => {
    const msg =
      e.response.data && e.response.data["message"]
        ? e.response.data["message"]
        : "Error in reading/ writing data.";
    present({
      message: msg,
      position: "middle",
      duration: 1500,
    });
  };
  return presentToast;
};
