import { IonButton, IonImg } from "@ionic/react";
import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../app/hooks";
import "./styles/ErpOverview.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { constructOutline } from "ionicons/icons";
import FormButton from "./FormButton";

const ErpOverview: React.FC = () => {
  let screenType: "PC" | "Tab" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });

  if (widthObj.width > 1100) screenType = "PC";
  else if (widthObj.width > 600) screenType = "Tab";
  else screenType = "Mobile";

  const PCLayoutJsx = (
    <div className="erp-overview-top">
      <div className="erp-overview-img-top">
        <img src="assets/images/ERP_img.png" className="erp-overview-img" />
      </div>
      <div className="erp-overview-text">
        <div>Complete</div>
        <div>School</div>
        <div>Solution</div>
        <div className="erp-overview-subtext">
          <div>Enabling NEP 2020</div>
        </div>
      </div>
      <div className="erp-overview-kids-img-top">
        <img src="assets/images/kids.png" className="erp-overview-img" />
      </div>
    </div>
  );

  const tabLayoutJsx = (
    <>
      <div className="erp-overview-text-tab-hdr">
        <div>Complete School Solution</div>
        <div className="erp-overview-subtext-tab">
          <div>Enabling Holistic Developement</div>
        </div>
      </div>
      <div className="erp-overview-tab-imgs">
        <div className="erp-overview-mob-get-started">
          <FormButton name="Get Started" isRound={true} />
        </div>
        <div className="erp-overview-img-top-tab">
          <img src="assets/images/ERP_img.png" className="erp-overview-img" />
        </div>
        <div className="erp-overview-kids-img-top-tab">
          <img src="assets/images/kids.png" className="erp-overview-img" />
        </div>
      </div>
    </>
  );

  const [swiperRef, setSwiperRef] = useState(null);
  const [imgSlide, setImgSlide] = useState<boolean>(true);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setImgSlide((val) => {
        const retVal = !val;
        return retVal;
      });
    }, 3000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  useEffect(() => {
    if (swiperRef && screenType === "Mobile")
      swiperRef.slideTo(!imgSlide ? 1 : 0);
  }, [imgSlide]);

  const mobileLayoutJsx = (
    <>
      <div className="erp-overview-text-mob-hdr">
        <div>Complete School Solution</div>
        <div className="erp-overview-subtext-mob">
          <div>Enabling NEP 2020</div>
        </div>
      </div>
      <div className="erp-overview-mob-img-wrpr">
        <div className="erp-overview-mob-get-started">
          <FormButton name="Get Started" isRound={true} />
        </div>
        <Swiper
          spaceBetween={1}
          initialSlide={0}
          slidesPerView={1}
          onSwiper={setSwiperRef}
        >
          <SwiperSlide>
            <div className="erp-overview-img-top-mob">
              <img
                src="assets/images/ERP_img.png"
                className="erp-overview-img"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="erp-overview-img-top-mob">
              <img src="assets/images/kids.png" className="erp-overview-img" />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  );

  return (
    <>
      {screenType === "PC" && PCLayoutJsx}
      {screenType === "Tab" && tabLayoutJsx}
      {screenType === "Mobile" && mobileLayoutJsx}
    </>
  );
};

export default ErpOverview;
