interface FeatureDataType {
  [key: string]: FeatureCardDataType;
}

interface FeatureCardDataType {
  header: string;
  subheader: string;
  imageName: string;
  text: string;
  steps: { header: string; text: string }[];
  isGrid: boolean;
}

interface StakeHolderCardData {
  imagePath: string;
  title: string;
  text: string[];
}

const stakeholderData: StakeHolderCardData[] = [
  {
    imagePath: "school.png",
    title: "School",
    text: [
      "Efficiently manage your institute(s) with powerful ERP. Optimize cost and improve efficiency with India's first Pay-As-You-Go and Pay-For-Use plan.",
    ],
  },
  {
    imagePath: "teacher.png",
    title: "Teacher",
    text: [
      "Automate day to day school tasks with powerful teacher App. Ease NEP-2020 Holistic development data recording.",
    ],
  },
  {
    imagePath: "student.png",
    title: "Student",
    text: [
      "Building a holistic 360 profile for all on Campus and Off-campus achievements. Personalized career recommendations and guidance.",
    ],
  },
  {
    imagePath: "parent.png",
    title: "Parent",
    text: [
      "Stay on top of your child's progress and efficient school communication. Track and assist your child's holistic development.",
    ],
  },
];

const featuresData: FeatureDataType = {
  erp: {
    header: "Powerful School ERP",
    subheader: "One platform to manage everything",
    imageName: "",
    text: "Manage your institute efficiently with automated flows",
    steps: [
      {
        header: "Fees Management",
        text: "Manage all fees at one place",
      },
      {
        header: "Admission & Student Management",
        text: "Manage student admission and data at one place",
      },
      {
        header: "Time Table & Exam Schedule",
        text: "Manage time table and scheduling exams at one place",
      },
      {
        header: "HR Functions",
        text: "Manage teachers and staff needs at one place",
      },
      {
        header: "Transport Management",
        text: "Manage all institute transport at one place",
      },
      {
        header: "And more ...",
        text: "Many more intuitive features to manage institure efficiently",
      },
    ],
    isGrid: true,
  },
  holisticReport: {
    header: "Year on Year Child's Holistic Profile",
    subheader: "Complete View On Child's Progress",
    imageName: "iprofile.png",
    text: "Enabling Holistic Development with iProfile",
    steps: [
      {
        header: "Holistic Progress Report",
        text: "Child's progress on Academic, Co-curricular and Co-scholastic",
      },
      {
        header: "360\u00B0 View on Developement",
        text: "A profile which covers both on-campus and off-campus achievements",
      },
      {
        header: "Track Progress",
        text: "Get insights on child's progress from profile statistics",
      },
    ],
    isGrid: false,
  },
  academicRecords: {
    header: "Cloud Based Mobile First LMS",
    subheader: "Simple interface with 3 easy steps",
    imageName: "student-report-review.png",
    text: "Managing Student Learning made easy",
    steps: [
      {
        header: "Record Marks",
        text: "Record Student marks on the go with intuitive mobile app",
      },
      {
        header: "Record Attendance",
        text: "Easy attendance record and review",
      },
      {
        header: "Publish Holistic Progress Card",
        text: "Publish holistic progress card for all students with a button click",
      },
    ],
    isGrid: false,
  },
  reportCardComment: {
    header: "Auto Report Card Comment",
    subheader: "Simple interface with 3 easy steps",
    imageName: "auto-reportcard-cmnt.png",
    text: "Hastle free Auto Report Card Comment generator",
    steps: [
      {
        header: "Record Anecdote",
        text: "Easy day to day recording of Student traits from all teachers",
      },
      {
        header: "Review Anecdotes",
        text: "Easy summary of student traints to class teacher",
      },
      {
        header: "Publish Generated Comment",
        text: "Review the auto generated comment from anecdotes and publish with a button click",
      },
    ],
    isGrid: false,
  },
  eventManagement: {
    header: "School Event Management",
    subheader: "Simple interface with 3 easy steps",
    imageName: "event-mgmnt.png",
    text: "Event Management in the palm of your hands",
    steps: [
      {
        header: "Create Event",
        text: "Create an event with a click of a button",
      },
      {
        header: "Register Participants",
        text: "Easy participant registration with event link and parent app",
      },
      {
        header: "Generate Certificates",
        text: "Record winners and generate certificates with a butotn click",
      },
    ],
    isGrid: false,
  },
};

const pricingText = {
  title: "Save Cost with flexible pricing",
  subtitle: "What you gain with pay-as-you-go model",
  features: [
    "Pay for what you use",
    "Pay for how much you use",
    "Efficient - Mobile First ERP",
    "No fixed cost commitment",
  ],
};

const aboutUs = [
  // "Inspired by Digital India movement, iDigizen offers first of its kind “pay-as-you-go” school ERP for a cost optimized scalable school ERP solution, freeing the schools from shackles of annual contracts.",
  "iDigizen’s iProfile embraces the NEP 2020’s vision for holistic development of a child. iProfile, an integrated profile enables every child to choose careers aligned with their inherent skills & interests.",
];

const footerData = {
  address: [
    "E 251A, opposite Paras Hospital, E - Block",
    "Sushant Lok Phase I, Sector 52A",
    "Gurugram, Haryana 122002",
  ],
  email: "info@idigizen.com",
  copyright: "©Copyright 2023 , iDigizen Pvt. Ltd.",
};

const formFillerDesignation = [
  "Teacher",
  "Student",
  "Pricipal",
  "Admin",
  "Owner/ Trustee",
  "None of the above",
];

const navLinkIDs = {
  solution: "solution",
  features: "features",
  pricing: "pricing",
  aboutus: "aboutus",
};

interface NavLinkRefType {
  ref: any;
  id: string;
}

// const iDigizenSolCardSteps: {
//   img: { path: string; description: string };
//   text: string;
// }[] = [
//   {
//     img: {
//       description: "Tell us your interests and hobbies",
//       path: "assets/images/hobbies.png",
//     },
//     text: "Tell us about your interests and hobbies.",
//   },
//   {
//     img: {
//       description: "Career recommendations on interests",
//       path: "assets/images/careers.png",
//     },
//     text: "Explore career options aligned with interests and hobbies.",
//   },
//   {
//     img: {
//       description: "Get an implementation plan for the career.",
//       path: "assets/images/plan.png",
//     },
//     text: "Select interested careers and iDigizen will create an action plan.",
//   },
//   {
//     img: {
//       description: "Data driven personalized career tracker",
//       path: "assets/images/profile.png",
//     },
//     text: "Build your profile to get personalized career recommendations.",
//   },
// ];
const iDigizenSolCardSteps: {
  img: { path: string; description: string };
  text: string;
}[] = [
  {
    img: {
      description: "Tell us your interests and hobbies",
      path: "assets/images/hobbies.png",
    },
    text: "Secure logging of all your learning records.",
  },
  {
    img: {
      description: "Career recommendations on interests",
      path: "assets/images/out_of_box_thinking.png",
    },
    text: "A very simple interface to record your learning records.",
  },
  {
    img: {
      description: "Get an implementation plan for the career.",
      path: "assets/images/plan.png",
    },
    text: "Data driven insights that help in goal setting and career guidance.",
  },
  {
    img: {
      description: "Data driven personalized career tracker",
      path: "assets/images/profile.png",
    },
    text: "A ready made profile for your College/ Job Applications.",
  },
];

const iDigizenSolutionFlashCards: {
  position: number;
  img: {
    path: string;
    description: string;
  };
  header: string;
  text: string[];
}[] = [
  {
    position: 0,
    header: "Which Career Suits You?",
    img: {
      description: "Passion meets Market Needs for Career Opportunity",
      path: "assets/images/pie.png",
    },
    text: [
      "In this dynamic job market there will always be “opportunity” where a person’s “passion & interest” is nurtured into skills to address a “market need”.",
      "Tell us your interests and passions and we will show you the aligned career opportunities.",
    ],
  },
  {
    position: 1,
    header: "What's my path to my Dream Career?",
    img: {
      description: "The different steps to take to achieve your career",
      path: "assets/images/steps.png",
    },
    text: [
      "Do you know which careers you are most excited by? There are multiple pathways you can take to achieve it.",
      "We will help you explore and pave the path to your dream career.",
    ],
  },
  {
    position: 2,
    header: "Know Thyself",
    img: {
      description: "The different steps to take to achieve your career",
      path: "assets/images/profile_prediction.png",
    },
    text: [
      "Do you know how your achievements help you gain skills for your dream career?",
      "Build your profile and we will show will show you insights into your strengths which may surprise you.",
    ],
  },
  {
    position: 3,
    header: "Am I on track to achieve my Dream?",
    img: {
      description: "The different steps to take to achieve your career",
      path: "assets/images/tracking.png",
    },
    text: [
      "Do you know which hard and soft skills are needed in the career of your dream?",
      "Follow the plan, keep updating your profile and see how you make progress towards your goal.",
    ],
  },
  {
    position: 4,
    header: "The Holistic Profile",
    img: {
      description: "Manage all your achievements under a single platform",
      path: "assets/images/portfolio.png",
    },
    text: [
      "Manage all your scholastic and co-scholastic achievements in a single profile, digitally secure forever.",
      "The holistic profile gives generates personalized career recommendations on your strengths.",
    ],
  },
];

// const appBulletText = [
// "Career Recommendation aligned with interests and strengths.",
// "Explore all possible paths leading to your Dream Career.",
// "Build a roadmap and track for achieving your Dream Career.",
// "A Profile Builder collating all your achievements in one place.",
// ];
const appBulletText = [
  "Comprehensive 360 degree digital portfolio.",
  "Secure cataloging of all learning records",
  "Learning insights for goal setting and career recommendation",
  "A ready to go profile for college/ job applications",
];

export {
  navLinkIDs,
  featuresData,
  pricingText,
  aboutUs,
  footerData,
  formFillerDesignation,
  stakeholderData,
  iDigizenSolCardSteps,
  iDigizenSolutionFlashCards,
  appBulletText,
};
export type {
  NavLinkRefType,
  FeatureDataType,
  FeatureCardDataType,
  StakeHolderCardData,
};
