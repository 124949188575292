import React from "react";
import { useAppSelector } from "../app/hooks";
import "./styles/SubHeader.css";

const SubHeader: React.FC<{ text: string; enableStickyScroll: boolean }> = (
  props
) => {
  let screenType: "PC" | "Tab" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });
  const offsetTop: { offset: number } = useAppSelector((state) => {
    return state.headerOffset;
  });

  if (widthObj.width > 1100) screenType = "PC";
  else if (widthObj.width > 600) screenType = "Tab";
  else screenType = "Mobile";

  const topOffsetStyle = {};
  if (props.enableStickyScroll) topOffsetStyle["top"] = offsetTop.offset;

  console.log("height offset ", offsetTop);
  const scrollCss = props.enableStickyScroll ? "subheader-sticky-scroll" : "";
  const fontCss =
    screenType === "PC"
      ? "subheader-font-pc"
      : screenType === "Tab"
      ? "subheader-font-tab"
      : "subheader-font-mob";
  return (
    <div className={`subheader ${scrollCss} ${fontCss}`} style={topOffsetStyle}>
      {props.text}
    </div>
  );
};

export default SubHeader;
