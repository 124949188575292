import React, { useEffect, useRef, useState } from "react";
import { featuresData, NavLinkRefType } from "../data/data";
import SubHeader from "../UI/SubHeader";
import "./styles/Features.css";
import { IonButton } from "@ionic/react";
import FeatureCard from "./FeatureCard";
import FormButton from "./FormButton";
import { useAppSelector } from "../app/hooks";

const Features: React.FC<{ navRef: NavLinkRefType }> = (props) => {
  const featuresKeys = Object.keys(featuresData);
  const [selectedHeaderKey, setSelectedHeaderKey] = useState<string>(
    featuresKeys[0]
  );
  const ref = useRef();

  let screenType: "PC" | "Tab" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });

  if (widthObj.width > 1350) screenType = "PC";
  else if (widthObj.width > 700) screenType = "Tab";
  else screenType = "Mobile";

  useEffect(() => {
    if (ref) props.navRef.ref.current[props.navRef.id] = ref;
  }, [ref]);

  const scrollSticky = screenType === "PC" ? false : true;

  const pcTypeJsx = (
    <div className="features-body">
      <div className="features-navbar">
        {featuresKeys.map((el, idx) => {
          const isSelected = el === selectedHeaderKey;
          const cssClass = isSelected
            ? "features-nav-list-common-selected"
            : "features-nav-list-common-not-selected";
          return (
            <div
              className={`features-nav-list-common ${cssClass}`}
              key={idx}
              onClick={() => {
                setSelectedHeaderKey(el);
              }}
            >
              {featuresData[el].header}
            </div>
          );
        })}
      </div>
      <div className="features-card-show-wrpr">
        <FeatureCard data={featuresData[selectedHeaderKey]} />
      </div>
    </div>
  );

  const keys = Object.keys(featuresData);

  const tabLTypeJsx = (
    <div>
      {keys.map((item, idx) => {
        const marginCss = featuresData[item].isGrid
          ? "features-card-show-wrpr-tab-bottom-pad"
          : "";
        return (
          <div className={`features-card-show-wrpr-tab ${marginCss}`} key={idx}>
            <FeatureCard data={featuresData[item]} />
          </div>
        );
      })}
    </div>
  );

  return (
    <div className="feature-top" ref={ref}>
      <SubHeader text="Features" enableStickyScroll={scrollSticky} />
      <div className="features-bg">
        {screenType === "PC" && pcTypeJsx}
        {(screenType === "Tab" || screenType === "Mobile") && tabLTypeJsx}
        <div className="feature-button">
          <FormButton name="Know More" />
        </div>
      </div>
    </div>
  );
};

export default Features;
