import { StoreData } from "../data/testiData";
import "./styles/LandingPageListing.css";
const LandingPageListing = () => {
  return (
    <>
      <div className="landing_listing-heading">Businesses Live</div>
      <div className="landing_listing_container">
        <div className="landing_listing_box">
          {StoreData.map((item) => {
            // const baseURL = "https://idigicator.idigizen.com/business/";
            const Domain = item.domaine;
            const link = `https://${Domain}.idigizen.com`;

            return (
              <a href={link}>
                <div>
                  <div className="landing_listing_img">
                    <img src={item.logo} alt="" />
                  </div>
                  <div className="landing_listing_name">{item.name}</div>
                </div>
              </a>
            );
          })}
          {StoreData.map((item) => {
            const Domain = item.domaine;
            // const link = baseURL + Domain;
            const link = `https://${Domain}.idigizen.com`;

            return (
              <a href={link}>
                <div>
                  <div className="landing_listing_img">
                    <img src={item.logo} alt="" />
                  </div>
                  <div className="landing_listing_name">{item.name}</div>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LandingPageListing;
