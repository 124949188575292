import { IonGrid, IonCol, IonRow } from "@ionic/react";
import "./styles/PastEvent.css";
import { useAppSelector } from "../app/hooks";
import { Collage, CollageText } from "./Collage";

const PastEvent = (props) => {
  let screenType: "PC" | "Tab" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });
  const btnStyle = props.btn;

  if (widthObj.width > 1100) screenType = "PC";
  else if (widthObj.width > 600) screenType = "Tab";
  else screenType = "Mobile";

  const PCLayoutJsx = (
    <>
      <div className="storelisting_backdrop">
        <div className="storelisting_container">
          <IonGrid>
            <IonRow class="ion-justify-content-between">
              <IonCol size="6">
                <CollageText btnStyle={btnStyle} />
              </IonCol>
              <IonCol size="6">
                <Collage />
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </>
  );

  const tabLayoutJsx = (
    <>
      <IonGrid>
        <IonRow class="ion-justify-content-around">
          <IonCol size="7">
            <CollageText btnStyle={btnStyle} />
          </IonCol>
          <IonCol size="7">
            <Collage />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );

  const mobileLayoutJsx = (
    <>
      <IonGrid>
        <IonRow class="ion-justify-content-around">
          <IonCol size="12">
            <CollageText btnStyle={btnStyle} />
          </IonCol>
          <IonCol size="12">
            <Collage />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );

  return (
    <>
      {screenType === "PC" && PCLayoutJsx}
      {screenType === "Tab" && tabLayoutJsx}
      {screenType === "Mobile" && mobileLayoutJsx}
    </>
  );
};

export default PastEvent;
