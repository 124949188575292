import React from "react";
import LandingStoreBanner from "../components/LandingStoreBanner";
import { BusinessLandingHeaderData } from "../data/LandingPageData";
import PricingTable from "../components/PricingTable";
import FeatureCardModern from "../components/FeatureCardModern";
import { arrowForwardCircle, logoWhatsapp } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import {
  IonHeader,
  IonButton,
  IonGrid,
  IonRow,
  IonPage,
  IonContent,
} from "@ionic/react";
import { useHistory } from "react-router";

import { BusinessLandingFeatureCardData } from "../data/LandingPageData";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  API_SECURE_KEY,
  IDIGICATOR_URL,
  SCHOOLREG_FORM_ID,
} from "../data/constants";
const BusinessLandingPage = () => {
  const history = useHistory();
  const goToRegistrationForm = () => {
    window.location.assign(
      `${IDIGICATOR_URL}forms?type=Form&id=205&Authorization=${API_SECURE_KEY}&submitOnly=true&logoName=iDigizen_Logo.png`
    );
  };

  const BannerCallToActionButtonJSX = (
    <>
      <IonButton
        shape="round"
        className="intrested_btn"
        color="success"
        fill="solid"
        onClick={() => {
          window.location.assign(
            "https://api.whatsapp.com/send?phone=+918076485914&text=Hello this is the starting message"
          );
        }}
      >
        <IonIcon
          size="large"
          className="intrested_btn_icon"
          icon={logoWhatsapp}
        />
        <div style={{ color: "white", fontWeight: "bold" }}>Intrested</div>
      </IonButton>
    </>
  );

  return (
    <>
      <IonContent>
        <Header
          navref={undefined}
          actionButtonName="Register Now!"
          onActionButtonClick={goToRegistrationForm}
          buttons={[
            {
              name: "Home",
              onClick: () => {
                history.push("/");
              },
            },
            {
              name: "School",
              onClick: () => {
                history.push("/erp");
              },
            },
            {
              name: "Stores",
              onClick: () => {
                history.push("/pastevents");
              },
            },
            {
              name: "IZPreneur",
              onClick: () => {
                history.push("/izpreneur");
              },
            },
          ]}
        />
        <LandingStoreBanner
          headerSubtitle={BusinessLandingHeaderData.header.subtitle}
          headerTitle={BusinessLandingHeaderData.header.titie}
          headerButton={BannerCallToActionButtonJSX}
          MobImage={BusinessLandingHeaderData.image.imageMob}
          WebImage={BusinessLandingHeaderData.image.imageWeb}
        />

        <div className="feature_card_container">
          <IonGrid>
            <IonRow>
              <div className="Feature_cards_title">
                <h1>{BusinessLandingFeatureCardData.header.title}</h1>
                <p>{BusinessLandingFeatureCardData.header.subtitiel}</p>
              </div>
            </IonRow>

            <IonRow className="feature_card_row">
              {BusinessLandingFeatureCardData.body.map((item) => {
                return (
                  <>
                    <FeatureCardModern
                      title={item.title}
                      img={item.imgPath}
                      text={item.text}
                    />
                  </>
                );
              })}
            </IonRow>
          </IonGrid>
        </div>
        <PricingTable />
        <Footer />
      </IonContent>
    </>
  );
};

export default BusinessLandingPage;
