import { configureStore } from "@reduxjs/toolkit";
import formModalOpenSlice from "./form-modal-open-slice";
import screenWidthSlice from "./screen-width-slice";
import headerOffsetSlice from "./header-offset-slice";

const store = configureStore({
  reducer: {
    formModalOpen: formModalOpenSlice.reducer,
    screenWidth: screenWidthSlice.reducer,
    headerOffset: headerOffsetSlice.reducer,
  },
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
