const IDIGICATOR_URL = "https://idigicator.idigizen.com/";
const IDIGIZEN_COURSES_URL = "https://courses.idigizen.com/";
const IDIGIZEN_BACKEND_URL = "https://api.newprod.idigizen.com";
const getFileThumbnailDB = "getThumbnail";

// const IDIGICATOR_URL = "http://localhost:3000/";
const SCHOOLREG_FORM_ID = 204;
const API_SECURE_KEY =
  "8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2";
export {
  IDIGICATOR_URL,
  SCHOOLREG_FORM_ID,
  API_SECURE_KEY,
  IDIGIZEN_COURSES_URL,
  IDIGIZEN_BACKEND_URL,
  getFileThumbnailDB,
};
