const videoBaseUrl = "https://asset.idigizen.com/files/upload";

export const LandingStoreBannerData = {
  header: {
    titie: "Launch you enterprise online in minutes",
    subtitle: [
      "Do you have a big business idea and don't know how to execute?",
      "Come, start your entrepreneurial journey with us! 360° solution for your online presence. ",
    ],
  },
  buttonText: "Open Your Store",
  image: {
    imageWeb: "assets/images/Store-1.png",
    imageMob: "assets/images/mb-store-1.png",
  },
};

export const FeaturesModernCardData = {
  header: {
    title: "Launch your online store in minutes.",
    subtitiel: "Launch with our zero-tech solution.",
  },
  body: [
    {
      imgPath: "assets/images/shopping-online.png",
      title: "Online catalogue",
      text: "Online catalogue to showcase your products and services. Showcase online and sell offline or online.",
    },
    {
      imgPath: "assets/images/online-shopping.png",
      title: "Online ordering",
      text: `Collect online orders and scale your business. 
            Schedule pick-ups or deliver to scale your business  `,
    },
    {
      imgPath: "assets/images/query.png",
      title: "Queries & Solicitations",
      text: `Receive queries and solicitations from your customers. 
             Manage your business from anywhere / anytime.`,
    },
    {
      imgPath: "assets/images/notifications.png",
      title: "Real-time notifications and updates",
      text: "Receive real-time notifications and updates on your mobile.",
    },
  ],
};

export const GraphicWithTextData = {
  heading: {
    title: `Want to start your online presence? `,
    subtitile: " Launch with our zero-tech solution.",
    video: `${videoBaseUrl}/launchYourStore.mp4`,
    cta: {
      text: "Launch Your Store",
      url: "https://idigicator.idigizen.com/home",
    },
  },
  data: [
    {
      imagepath: "assets/images/meditation.png",
      text: {
        title: "Zero tech online launch",
        points: [
          "Point and click to add products and services.",
          "Automated setup for receiving online orders.",
          "Bulk upload of product catalogues.",
          "Choose from multiple themes to customize your store.",
        ],
      },
      video: `${videoBaseUrl}/launchYourStore.mp4`,
      cta: {
        text: "Sign Up",
        url: "https://idigicator.idigizen.com/home ",
      },
    },
    {
      imagepath: "assets/images/userFriendly.png",
      text: {
        title: "User friendly online store",
        points: [
          "Mobile friendly e-commerce website.",
          "Simple user-friendly design for ease of use.",
          "Automated receipt and order confirmation.",
          "Manage payments and deliveries offline.",
          "In-built user help and tutorials.",
        ],
      },
      video: `${videoBaseUrl}/UserStoreDemo.mp4`,
      cta: {
        text: "Sign Up",
        url: "https://idigicator.idigizen.com/business/testdomain",
      },
    },
    {
      imagepath: "assets/images/exx.png",
      text: {
        title: "Expand your business",
        points: [
          "Integrated tracking of orders.",
          "Consolidated customer query management.",
          "Track sales online.",
          "Electronic invoices for tracking.",
          "Business analytic reports to track sales.",
        ],
      },
      video: `${videoBaseUrl}/OnlineTracking.mp4`,
      cta: {
        text: "Sign Up",
        url: "https://idigicator.idigizen.com/business/testdomain",
      },
    },
  ],
};

//  Business Landing page data

export const BusinessLandingHeaderData = {
  header: {
    titie:
      "Your <i class='DangerousHtmlText' style='color :#0e918c'>Local Market</i>  is online. Come be a part of it !",

    subtitle: [
      " Boost sales.   Enable your customers to reach you online. ",
      " Launch your online store in 5 minutes.",

      // "Revolutionize the way you reach customers—get your shop seamlessly listed in your local market online in just 5 minutes.",
      // " Embrace the power of modern technology to effortlessly expand your digital presence, connect with a wider audience, and unlock a world of new opportunities.",
      // " Don't let time be a barrier; take the first step towards online growth and success today!",
    ],
  },
  buttonText: "Intrested",
  image: {
    imageWeb: "assets/images/Store-1.png",
    imageMob: "assets/images/mb-store-1.png",
  },
};

export const BusinessLandingFeatureCardData = {
  header: {
    title: "Launch your online store in minutes.",
    subtitiel: "Launch with our zero-tech solution.",
  },
  body: [
    {
      imgPath: "assets/images/shopping-online.png",
      title: "List Your Shop",
      text: "Revolutionize the way you reach customers—get your shop seamlessly listed in your local market online in just 5 minutes !",
    },
    {
      imgPath: "assets/images/brand.png",
      title: "Build A Recognizable Brand",
      text: ` 
      Elevate your brand's presence within your local market and establish a strong online presence.   `,
    },
    {
      imgPath: "assets/images/gift.png",
      title: "Launch your loyalty program",
      text: `Strengthen your customer relationships, boost sales, and secure long-term 
      success with an effective loyalty program!`,
    },
  ],
};
