import React from "react";
import IDigizenHeaderBandCard from "../UI/IDigizenHeaderBandCard";
import { IonCol, IonGrid, IonRow } from "@ionic/react";
import IDigizenImgCard from "./IDigizenImgCard";
import { useAppSelector } from "../app/hooks";
import { getScreenType } from "../data/breakpoints";

const IDigizenBandWithImgCard: React.FC<{
  cards: { img: { file: string; text: string }; text: string }[];
  header: string;
  headerPosition: "right" | "left";
  bgColor: boolean;
}> = (props) => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);
  const size = screenType === "PC" ? "3" : screenType === "Tab" ? "4" : "12";
  const offset = screenType === "PC" ? "0.75" : "0";
  return (
    <IDigizenHeaderBandCard
      bgColor={props.bgColor}
      header={props.header}
      headerPosition={props.headerPosition}
    >
      <IonGrid>
        <IonRow>
          {props.cards.map((el) => {
            return (
              <IonCol size={size} offset={offset}>
                <IDigizenImgCard
                  img={{ path: el.img.file, description: el.img.text }}
                  text={el.text}
                  fullHeight={screenType === "Mobile" ? false : true}
                />
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
    </IDigizenHeaderBandCard>
  );
};

export default IDigizenBandWithImgCard;
