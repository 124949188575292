import React from "react";
import { useAppSelector } from "../app/hooks";
import { getScreenType } from "../data/breakpoints";
import { IonButton } from "@ionic/react";
import {
  API_SECURE_KEY,
  IDIGICATOR_URL,
  SCHOOLREG_FORM_ID,
} from "../data/constants";

const IDigizenImgBand: React.FC<{
  imgPosition: "right" | "left";
  bgColor: boolean;
  data: {
    img: { path: string; description: string };
    text: string[] | { text: string[]; header: string }[];
    heading: string;
  };
  buttonName?: string;
  onButtonClick?: () => void;
  onButtonClickLink?: string;
}> = (props) => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);

  const pcJsx = (
    <div
      className={`idigizen-solution-band ${
        props.bgColor
          ? "idigizen-solution-blue-band"
          : "idigizen-solution-no-band"
      } ${
        props.imgPosition === "left"
          ? "idigizen-solution-img-left-band"
          : "idigizen-solution-img-right-band"
      }`}
    >
      <img
        className={
          screenType === "PC"
            ? "idigizen-solution-pie-img"
            : "idigizen-solution-pie-img-tab"
        }
        src={props.data.img.path}
        alt={props.data.img.description}
      />
      <div
        className={`idigizen-solution-pie-text ${
          props.imgPosition === "left"
            ? "idigizen-solution-pie-text-img-left"
            : "idigizen-solution-pie-text-img-right"
        }`}
      >
        <div
          className={`idigizen-solution-pie-hdr ${
            props.imgPosition === "left"
              ? "idigizen-solution-pie-hdr-img-left"
              : "idigizen-solution-pie-hdr-img-right"
          }        
        `}
        >
          {props.data.heading}
        </div>
        <div style={{ marginBottom: "0.5em" }}>
          {props.data.text.map((el) => {
            if (typeof el !== "object") return <p>{el}</p>;
            return (
              <div style={{ marginBottom: "0.5em" }}>
                <div
                  style={{
                    color: "var(--ion-color-tertiary)",
                    borderBottom: "2px solid var(--ion-color-secondary)",
                    fontWeight: "bold",
                    marginBottom: "0.2em",
                  }}
                >
                  {el.header}
                </div>
                <div>
                  {el.text.map((item) => {
                    return <div>{item}</div>;
                  })}
                </div>
              </div>
            );
          })}
        </div>
        <IonButton
          color={props.bgColor ? "secondary" : "tertiary"}
          shape="round"
          onClick={() => {
            if (props.onButtonClick) {
              props.onButtonClick();
              return;
            }
            const link = props.onButtonClickLink
              ? props.onButtonClickLink
              : `${IDIGICATOR_URL}forms?type=Form&id=${SCHOOLREG_FORM_ID}&Authorization=${API_SECURE_KEY}&submitOnly=true`;
            window.location.assign(link);
          }}
        >
          {!props.buttonName ? "Get Started" : props.buttonName}
        </IonButton>
      </div>
    </div>
  );

  const mobileJsx = (
    <div
      className={`idigizen-solution-band-mob ${
        props.bgColor
          ? "idigizen-solution-blue-band"
          : "idigizen-solution-no-band"
      }`}
    >
      <div
        className={`idigizen-solution-pie-hdr-mob ${
          props.imgPosition === "left"
            ? "idigizen-solution-pie-hdr-img-left-mob"
            : "idigizen-solution-pie-hdr-img-right-mob"
        }        
        `}
      >
        {props.data.heading}
      </div>
      <img
        className="idigizen-solution-pie-img-mob"
        src={props.data.img.path}
        alt={props.data.img.description}
      />
      <div className={`idigizen-solution-pie-text-mob`}>
        {props.data.text.map((el) => {
          if (typeof el !== "object") return <p>{el}</p>;
          return (
            <div>
              <div
                style={{
                  fontWeight: "bold",
                  borderBottom: "1.5px solid var(--ion-color-secondary)",
                  color: "var(--ion-color-tertiary)",
                }}
              >
                {el.header}
              </div>
              <ul>
                {el.text.map((item) => {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
          );
        })}
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <IonButton
          color={props.bgColor ? "secondary" : "tertiary"}
          shape="round"
          onClick={() => {
            if (props.onButtonClick) {
              props.onButtonClick();
              return;
            }
            window.location.assign(
              `${IDIGICATOR_URL}forms?type=Form&id=${SCHOOLREG_FORM_ID}&Authorization=${API_SECURE_KEY}&submitOnly=true`
            );
          }}
        >
          {!props.buttonName ? "Get Started" : props.buttonName}
        </IonButton>
      </div>
    </div>
  );

  return (
    <>
      {screenType === "PC" && pcJsx}
      {screenType === "Tab" && pcJsx}
      {screenType === "Mobile" && mobileJsx}
    </>
  );
};

export default IDigizenImgBand;
