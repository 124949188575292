import { IonCard } from "@ionic/react";
import React from "react";
import { useAppSelector } from "../app/hooks";
import { StakeHolderCardData } from "../data/data";
import Card from "../UI/Card";
import "./styles/StakeHolderCard.css";

const StakeHolderCard: React.FC<{ data: StakeHolderCardData }> = (props) => {
  let screenType: "PC" | "Tab" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });

  if (widthObj.width > 1100) screenType = "PC";
  else if (widthObj.width > 600) screenType = "Tab";
  else screenType = "Mobile";

  return (
    <Card>
      <div className="stakeholder-card-top">
        <div className="stakeholder-card-img-wrpr">
          <img
            src={`assets/images/${props.data.imagePath}`}
            alt=""
            className="stakeholder-card-img"
          />
        </div>
        <div className="stakeholder-card-text">
          <div
            className={
              screenType === "PC"
                ? "stakeholder-card-text-title"
                : screenType === "Tab"
                ? "stakeholder-card-text-title-tab"
                : "stakeholder-card-text-title-mob"
            }
          >
            {props.data.title}
          </div>
          <div className="stakeholder-card-text-para">
            {props.data.text.map((el, idx) => {
              return (
                <div
                  className={
                    screenType === "PC"
                      ? "stakeholder-card-text-para-el"
                      : screenType === "Tab"
                      ? "stakeholder-card-text-para-el-tab"
                      : "stakeholder-card-text-para-el-mob"
                  }
                  key={idx}
                >
                  {el}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default StakeHolderCard;
