import { IonGrid, IonCol, IonRow } from "@ionic/react";
import { useAppSelector } from "../app/hooks";
import { pastEventCard } from "../data/cardwithnumData";
import "./styles/CardWithNum.css";

const CardWithNum = () => {
  let screenType: "PC" | "Tab" | "Mobile" = "PC";

  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });

  if (widthObj.width > 1100) screenType = "PC";
  else if (widthObj.width > 600) screenType = "Tab";
  else screenType = "Mobile";

  const PCLayoutJsx = (
    <>
      <IonGrid>
        <IonRow>
          {pastEventCard.map((item) => {
            return (
              <>
                <IonCol size="3" offset="0.75" className="cardwithnum-col">
                  <div className="cardwithnum-card">
                    <div className="cardwithnum-card-number">{item.number}</div>

                    <div className="cardwithnum-card-text">{item.text} </div>
                  </div>
                </IonCol>
              </>
            );
          })}
        </IonRow>
      </IonGrid>
    </>
  );

  const tabLayoutJsx = (
    <>
      <IonGrid>
        <IonRow>
          {pastEventCard.map((item) => {
            return (
              <>
                <IonCol size="3" offset="0.75" className="cardwithnum-col">
                  <div className="cardwithnum-card">
                    <div className="cardwithnum-card-number-tab">
                      {item.number}
                    </div>

                    <div className="cardwithnum-card-text-tab">
                      {item.text}{" "}
                    </div>
                  </div>
                </IonCol>
              </>
            );
          })}
        </IonRow>
      </IonGrid>
    </>
  );

  const mobileLayoutJsx = (
    <>
      <IonGrid>
        <IonRow>
          {pastEventCard.map((item) => {
            return (
              <>
                <IonCol size="3.5" offset=".375" className="cardwithnum-col">
                  <div className="cardwithnum-card">
                    <div className="cardwithnum-card-number">{item.number}</div>

                    <div
                      className="cardwithnum-card-text"
                      style={{ textAlign: "center", paddingBottom: "10px" }}
                    >
                      {item.text}
                    </div>
                  </div>
                </IonCol>
              </>
            );
          })}
        </IonRow>
      </IonGrid>
    </>
  );

  return (
    <>
      {screenType === "PC" && PCLayoutJsx}
      {screenType === "Tab" && tabLayoutJsx}
      {screenType === "Mobile" && mobileLayoutJsx}
    </>
  );
};

export default CardWithNum;
