import { IonButton, IonContent, IonPage } from "@ionic/react";
import Header from "../components/Header";
import { useHistory } from "react-router";
import { useAppSelector } from "../app/hooks";
import { getScreenType } from "../data/breakpoints";
import Footer from "../components/Footer";
import PastEvent from "../components/PastEvent";
import Typewriter from "typewriter-effect";

import {
  testimonial_kid,
  testimonial_Parents,
  testimonial_judges,
} from "../data/testiData";
import TestiCrousel from "../components/EventTesti";
import {
  API_SECURE_KEY,
  IDIGICATOR_URL,
  SCHOOLREG_FORM_ID,
} from "../data/constants";
import StoreListing from "../components/StoreListing";
import IDigizenHeaderBandCard from "../UI/IDigizenHeaderBandCard";

const Stores = () => {
  const history = useHistory();
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);
  const textCss = screenType === "PC" ? "izp-text" : "izp-text-mob";
  const subTextCss = screenType === "PC" ? "izp-subtext" : "izp-subtext-mob";
  const titleCss = screenType === "PC" ? "izp-title" : "izp-title-mob";
  const parentOrientationDate = "14th May, 11AM";
  const goToRegistrationForm = () => {
    window.location.assign(
      `${IDIGICATOR_URL}forms?type=Form&id=205&Authorization=${API_SECURE_KEY}&submitOnly=true&logoName=iDigizen_Logo.png`
    );
  };

  return (
    <>
      <IonPage>
        <IonContent className="idigizen-solution-content" id="popover-id">
          <Header
            navref={undefined}
            actionButtonName="Interested!"
            onActionButtonClick={goToRegistrationForm}
            buttons={[
              {
                name: "Home",
                onClick: () => {
                  history.push("/");
                },
              },
              {
                name: "iProfile",
                onClick: () => {
                  history.push("/idigizen-solution");
                },
              },
              // {
              //   name: "School",
              //   onClick: () => {
              //     history.push("/erp");
              //   },
              // },
              // {
              //   name: "Business Owner",
              //   onClick: () => {
              //     window.location.assign(
              //       `${IDIGICATOR_URL}forms?type=Form&id=${SCHOOLREG_FORM_ID}&Authorization=${API_SECURE_KEY}&submitOnly=true`
              //     );
              //   },
              // },
              {
                name: "iZPreneur",
                onClick: () => {
                  history.push("/izpreneur");
                },
              },
              {
                name: "Platform",
                onClick: () => {
                  history.push("/storeslanding");
                },
              },
            ]}
          />

          {/* <div className="pasteventbanner">
            <div className="pasteventbanner_right"></div>
            <div className="pasteventbanner_left">
              <div className="pasteventbanner_left_text">
                <h1>
                  {" "}
                  <span className="redtext">iZ</span>Preneur
                </h1>
                <h2># Work For Passion </h2>

                <Typewriter
                  onInit={(typewriter) => {
                    typewriter
                      .typeString(
                        "Celebrating Entrepreneurial Triumphs of the Past"
                      )
                      .pauseFor(1000)
                      // .deleteAll()
                      // .typeString("Welcomes You ")
                      .start();
                  }}
                />
              </div>
            </div>
          </div> */}
          <div id="pastevents"></div>
          {/* <IDigizenHeaderBandCard
            bgColor={true}
            header=" "
            headerPosition="right"
          >
                        <PastEvent btn={"none"} />

      
          </IDigizenHeaderBandCard> */}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              margin: "40px 10px 10px 10px",
              textAlign: "center",
            }}
          >
            <div id="stores"></div>

            <div className={titleCss}>iZPreneur Business Stores</div>
            <div
              className={subTextCss}
              style={{
                color: "var(--ion-color-secondary)",
                fontWeight: "bold",
              }}
            >
              <div>From Passion To Business</div>
            </div>
          </div>
          <IDigizenHeaderBandCard
            bgColor={true}
            header=""
            headerPosition="right"
          >
            <div
              className="idigizen-solution-no-band"
              style={{
                width: "100vw",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              {/* <div className={textCss}>New Batch from July 8</div> */}
              <div className={subTextCss}>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "0.5em",
                  }}
                >
                  <IonButton
                    color={"secondary"}
                    shape="round"
                    onClick={goToRegistrationForm}
                  >
                    Interested!
                  </IonButton>
                </div>
                <div
                  id="testimonials"
                  style={{ color: "var(--ion-color-danger)" }}
                >
                  *Hurry, Limited seats only!
                </div>
              </div>
            </div>
          </IDigizenHeaderBandCard>
          <div className={textCss} style={{ textAlign: "center" }}>
            Explore Stores and Businesses by our iZPreneur
          </div>
          <StoreListing />

          <div></div>
          <TestiCrousel
            DataSet={testimonial_kid}
            bg={true}
            headerPosition="right"
          />
          <TestiCrousel
            DataSet={testimonial_Parents}
            bg={false}
            headerPosition="left"
          />
          <TestiCrousel
            DataSet={testimonial_judges}
            bg={true}
            headerPosition="right"
          />

          <Footer />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Stores;
