import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import Header from "../components/Header";
import WorkForPassionBanner from "../components/WorkForPassionBanner";
import IDigizenFeaturesCard from "../components/IDigizenFeaturesCard";
import { appBulletText } from "../data/data";
import IDigizenImgCard from "../components/IDigizenImgCard";
import { useAppSelector } from "../app/hooks";
import { getScreenType } from "../data/breakpoints";
import "./styles/IZPreneur.css";
import IDigizenHeaderBandCard from "../UI/IDigizenHeaderBandCard";
import IDigizenBandWithImgCard from "../components/IDigizenBandWithImgCard";
import IDigizenImgBand from "../components/IDigizenImgBand";
import Footer from "../components/Footer";
import Card from "../UI/Card";
import { testimonial_all, testimonial_Parents } from "../data/testiData";
import {
  API_SECURE_KEY,
  IDIGICATOR_URL,
  SCHOOLREG_FORM_ID,
} from "../data/constants";
import TheTeam from "../components/TheTeam";
import IDigizenPopup from "../components/IDigizenPopup";
import { useHistory } from "react-router";
import TestiCrousel from "../components/EventTesti";
import PastEvent from "../components/PastEvent";
import CardWithNum from "../components/CardWithNum";

const IZPreneur: React.FC = () => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);
  const appBulletText = [
    "India's 1st platform to launch a business online in minutes.",
    "An E-commerce platform to scale your business.",
    "Track your orders and payments to completion.",
    "Record sales & expenses and get profit & loss statement.",
  ];

  const programNeedText = [
    {
      header: "Entrepreneurship Workshop, Why?",
      cards: [
        {
          img: {
            file: "assets/images/ai.png",
            text: "technology advancements",
          },
          text: "Technology advancements are making entrepreneurship more accessible and in-demand.",
        },
        {
          img: {
            file: "assets/images/job_trend.png",
            text: "Job trend changes",
          },
          text: "Changing job trends increase demand for entrepreneurship as a skill.",
        },
        {
          img: {
            file: "assets/images/thinking_framework.png",
            text: "thinking framework",
          },
          text: "Entrepreneurship teaches adaptive thinking necessary in today's rapidly evolving world.",
        },
      ],
    },
    // {
    //   header: "What do you learn?",
    //   cards: [
    //     {
    //       img: {
    //         file: "assets/images/new_age_skills.png",
    //         text: "new age skills",
    //       },
    //       text: "Learn the new age skills to be successful in tomorrow's world.",
    //     },
    //     {
    //       img: {
    //         file: "assets/images/out_of_box_thinking.png",
    //         text: "Out of box thinking",
    //       },
    //       text: "Think out of box to spot an opportunity and build it into a business.",
    //     },
    //     {
    //       img: {
    //         file: "assets/images/real_world_execution.png",
    //         text: "real world execution",
    //       },
    //       text: "Get a taste of real business operations by working with real customers.",
    //     },
    //   ],
    // },
  ];

  const overviewData = [
    {
      img: {
        path: "assets/images/IZ-ideate.png",
        description: "Ideate the new start up idea",
      },
      text: "Learn Design Thinking & Launch your 1st online store.",
      title: "Launch Your Store",
    },
    {
      img: {
        path: "assets/images/IZ-execute.png",
        description: "Put a plan around the idea",
      },
      text: "Learn Financial Literacy & Execute your business with real customers.",
      title: "Earn While You Learn",
    },
    {
      img: {
        path: "assets/images/IZ-plan.png",
        description: "Execute the plan",
      },
      text: "Learn Business Presentation & Pitch to zSharks.",
      title: "zSharks",
    },
  ];

  const programOverview = {
    heading: "Program Overview",
    text: [
      {
        header: "Part 1",
        text: [
          "Introduction to Entrepreneurship.",
          "Ideate a product and build a business plan.",
        ],
      },
      {
        header: "Part 2",
        text: [
          "Launch your online business and execute.",
          "Network with fellow zPreneurs for business collaborations.",
          "Regular checkpoints with mentors.",
        ],
      },
      {
        header: "Conclusion",
        text: [
          "Showcase business and results to a panel of Entrepreneurs.",
          "Best judged entries to win exciting prizes.",
        ],
      },
    ],
    img: {
      path: "assets/images/programme.png",
      description: "programme details",
    },
  };

  const workshopDates = [
    {
      batch: "Batch 1",
      startDate: "22nd May, 2023",
      duration: "2 Weeks",
      eligibility: "Students between classes 6 to 12",
      timing: "10:30AM - 1PM",
    },
    {
      batch: "Batch 2",
      startDate: "12th June, 2023",
      duration: "2 Weeks",
      eligibility: "Students between classes 6 to 12",
      timing: "10:30AM - 1PM",
    },
  ];
  const subTextCss = screenType === "PC" ? "izp-subtext" : "izp-subtext-mob";

  const pcEligibilityTableJsx = (
    <IonGrid>
      <IonRow>
        <IonCol className="izp-table izp-header">#</IonCol>
        <IonCol className="izp-table izp-header">Type</IonCol>
        <IonCol className="izp-table izp-header">Location</IonCol>
        <IonCol className="izp-table izp-header">Timing</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="izp-table">Week 1 (Sat-Sun)</IonCol>
        <IonCol className="izp-table">Classroom Session</IonCol>
        <IonCol className="izp-table">Online / Offline (Gurugram)</IonCol>
        <IonCol className="izp-table">11.30AM - 2PM</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="izp-table">Week 2 (Mon-Wed-Thurs)</IonCol>
        <IonCol className="izp-table">1:1 Mentorship</IonCol>
        <IonCol className="izp-table">Online </IonCol>
        <IonCol className="izp-table">Mutually Decided</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="izp-table">Week 2 (Sat-Sun)</IonCol>
        <IonCol className="izp-table">Classroom Session</IonCol>
        <IonCol className="izp-table">Online / Offline (Gurugram)</IonCol>
        <IonCol className="izp-table">11.30AM - 2PM</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="izp-table">Week 3 (Mon-Wed-Thurs)</IonCol>
        <IonCol className="izp-table">1:1 Mentorship</IonCol>
        <IonCol className="izp-table">Online </IonCol>
        <IonCol className="izp-table">Mutually Decided</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="izp-table">Week 3 (Sat-Sun)</IonCol>
        <IonCol className="izp-table">Classroom Session</IonCol>
        <IonCol className="izp-table">Online / Offline (Gurugram)</IonCol>
        <IonCol className="izp-table">11.30AM - 2PM</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="izp-table">Week 4 (Mon)</IonCol>
        <IonCol className="izp-table">zShark Finale</IonCol>
        <IonCol className="izp-table">Online / Offline (Gurugram)</IonCol>
        <IonCol className="izp-table">6:00PM-9PM</IonCol>
      </IonRow>
    </IonGrid>
  );

  const mobEligibilityTableJsx = (
    <div style={{ marginTop: "0.5em" }}>
      <IonGrid>
        <IonRow>
          <IonCol className="izp-table izp-header">#</IonCol>
          <IonCol className="izp-table izp-header">Type</IonCol>
          <IonCol className="izp-table izp-header">Timing</IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="izp-table">Week 1 (Sat-Sun)</IonCol>
          <IonCol className="izp-table">Classroom </IonCol>
          <IonCol className="izp-table">11.30AM - 2PM</IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="izp-table">Week 2 (Mon-Wed-Thurs)</IonCol>
          <IonCol className="izp-table">Mentorship</IonCol>
          <IonCol className="izp-table">Mutually Decided</IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="izp-table">Week 2 (Sat-Sun)</IonCol>
          <IonCol className="izp-table">Classroom </IonCol>
          <IonCol className="izp-table">11.30AM - 2PM</IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="izp-table">Week 3 (Mon-Wed-Thurs)</IonCol>
          <IonCol className="izp-table">Mentorship</IonCol>
          <IonCol className="izp-table">Mutually Decided</IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="izp-table">Week 3 (Sat-Sun)</IonCol>
          <IonCol className="izp-table">Classroom </IonCol>
          <IonCol className="izp-table">11.30AM - 2PM</IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="izp-table">Week 4 (Mon)</IonCol>
          <IonCol className="izp-table">zShark Finale</IonCol>
          <IonCol className="izp-table">6:00PM-9PM</IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="izp-table">
            <div style={{ fontSize: "small" }}>
              <div>
                <b>Classroom: </b>
                Online / Offline (Gurugram)
              </div>
              <div>
                <b>Mentorship: </b>
                1:1 sessions Online
              </div>
              <div>
                <b>zShark: </b>
                Presentation Online / Offline (Gurugram)
              </div>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );

  const goToRegistrationForm = () => {
    window.location.assign(
      `${IDIGICATOR_URL}forms?type=Form&id=205&Authorization=${API_SECURE_KEY}&submitOnly=true&logoName=iDigizen_Logo.png`
    );
  };

  const goToOrientationForm = () => {
    window.location.assign(
      `${IDIGICATOR_URL}forms?type=Form&id=206&Authorization=${API_SECURE_KEY}&submitOnly=true&logoName=iDigizen_Logo.png`
    );
  };

  const textCss = screenType === "PC" ? "izp-text" : "izp-text-mob";
  const titleCss = screenType === "PC" ? "izp-title" : "izp-title-mob";
  // const parentOrientationDate = "14th May, 11AM";

  const overviewJsx = (
    <div className="izp-overview">
      <div className={titleCss}>
        iZPreneur <span className="overview_dash"> - </span>{" "}
        <span className="subTitleTransforming">
          {" "}
          Transforming Passion To Business
        </span>{" "}
      </div>
      <div className={textCss}>Get Certified as a Student Entrepreneur</div>
      <div
        className={subTextCss}
        style={{ color: "var(--ion-color-secondary)", fontWeight: "bold" }}
      >
        {/* <div>From Passion To Business</div> */}
      </div>
      <IonGrid>
        <IonRow>
          {overviewData.map((el) => {
            return (
              <IonCol
                size={
                  screenType == "PC" ? "3" : screenType === "Tab" ? "4" : "12"
                }
                offset={screenType === "PC" ? "0.75" : "0"}
              >
                <IDigizenImgCard
                  header={el.title}
                  img={el.img}
                  text={el.text}
                  fullHeight={screenType === "Mobile" ? false : true}
                />
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
      <div className={textCss}>A Program To Launch Your First Start-Up</div>
      <div className={subTextCss}>
        <div>
          <b>Eligibility: </b>
          Students from class 6-12
        </div>
      </div>
      <div className={subTextCss}>
        <div>
          <b>Price: </b>
          <span>&#8377; 5000</span>{" "}
          {/* <span style={{ color: "var(--ion-color-secondary)" }}>5000 INR </span>
          (Early Bird Discount) */}
        </div>
      </div>
      <div className={subTextCss}>
        {/* <b>Next Batch: </b> 5th Aug - 20th Aug */}
      </div>
      <div>{screenType !== "Mobile" && pcEligibilityTableJsx}</div>
      <div>{screenType === "Mobile" && mobEligibilityTableJsx}</div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginTop: "0.5em",
        }}
      >
        <IonButton
          color={"secondary"}
          shape="round"
          onClick={goToRegistrationForm}
        >
          Interested!
        </IonButton>
      </div>
      <div style={{ color: "var(--ion-color-danger)" }}>
        *Hurry, Limited seats only!
      </div>
    </div>
  );

  // const parentOrientationJsx = (
  //   <div className="izp-parent-orientation">
  //     <div className="izp-parent-orientation-title">Still Not Sure?</div>
  //     Join us on{" "}
  //     <b>
  //       {parentOrientationDate} for a free 30min Parent Orientation Program.
  //     </b>{" "}
  //     <div style={{ margin: "1em" }}>
  //       <IonButton
  //         color={"tertiary"}
  //         shape="round"
  //         onClick={goToOrientationForm}
  //       >
  //         Register
  //       </IonButton>
  //     </div>
  //   </div>
  // );

  const history = useHistory();

  return (
    <>
      <IonPage>
        <Header
          navref={undefined}
          actionButtonName="Interested!"
          onActionButtonClick={goToRegistrationForm}
          buttons={[
            {
              name: "Home",
              onClick: () => {
                history.push("/");
              },
            },
            // {
            //   name: "School",
            //   onClick: () => {
            //     history.push("/erp");
            //   },
            // },
            {
              name: "Platform",
              onClick: () => {
                history.push("/storeslanding");
              },
            },
            {
              name: "Stores",
              onClick: () => {
                history.push("/pastevents");
              },
            },
          ]}
        />

        <IonContent className="idigizen-solution-content">
          <WorkForPassionBanner
            kidSubheader="Turn your"
            kidHeader="Interests & Hobbies..."
            adultSubheader="...into a"
            adultHeader="Successful Enterprise"
            hashtag="Work For Passion"
            buttonName="INTERESTED!"
            onButtonClick={goToRegistrationForm}
          />
          {overviewJsx}
          {programNeedText.map((el, idx) => {
            const position = idx % 2 ? "left" : "right";
            return (
              <IDigizenBandWithImgCard
                bgColor={!(idx % 2)}
                cards={el.cards}
                header={el.header}
                headerPosition={position}
              />
            );
          })}
          <IDigizenImgBand
            bgColor={false}
            data={programOverview}
            imgPosition="right"
            buttonName="Interested!"
            onButtonClick={goToRegistrationForm}
          />
          <IDigizenFeaturesCard
            headerPC={["Execute Your", "Business.", "Leave Tech", "To Us."]}
            headerMob={["Execute Your Business.", "Leave Tech To Us."]}
            img={{
              path: "assets/images/bakery.png",
              text: "iDigizen career recommendation/ counselling app",
            }}
            bulletText={appBulletText}
            button={{
              name: "Explore Platform",
              onClick: () => {
                history.push("/storeslanding");
              },
            }}
          />
          <IDigizenHeaderBandCard
            bgColor={false}
            header="iZPreneur - Build a business from your Passion & Interest"
            headerPosition="left"
          >
            <div
              style={{
                display: "flex",
                flexDirection: screenType === "Mobile" ? "column" : "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                className="idigizen-solution-pie-text-mob"
                style={{ width: screenType === "Mobile" ? "100%" : "70%" }}
              >
                <p>
                  iZPreneur is a unique program for students to experience real
                  life entrepreneurship by ideating, launching and managing
                  their first start-up.
                </p>
                <p>
                  Using the iZPreneur platform a student can launch their big
                  business idea online in minutes. With the latest features, the
                  iZPreneur platform helps the student seamlessly execute
                  his/her business.
                </p>
                <p>
                  For those who do not have a business idea, 'iZPreneur' also
                  has 3-week workshop. In this instructor led workshop students
                  will be taught the basics of entrepreneurship and guided to
                  launch and execute a business ideated from their passions and
                  interests.
                </p>
                <p>
                  Through iZPreneur, students will gain valuable skills,
                  knowledge, and confidence to become successful professionals
                  in tomorrow’s world. We hope that this experience will inspire
                  them to think creatively, take calculated risks, and make a
                  positive impact in their communities.
                </p>
              </div>
              <div
                style={{ marginLeft: screenType === "Mobile" ? "0" : "20px" }}
              >
                <iframe
                  width={screenType === "Mobile" ? "350" : "444"}
                  height={screenType === "Mobile" ? "197" : "250"}
                  src="https://www.youtube.com/embed/KhNwCoMsNJk"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </div>
            </div>
          </IDigizenHeaderBandCard>

          {/* {parentOrientationJsx} */}
          <div id="pastevents"></div>
          <IDigizenHeaderBandCard
            bgColor={true}
            header="Past Events"
            headerPosition="right"
          >
            <CardWithNum />
            <PastEvent btn={"flex"} />
          </IDigizenHeaderBandCard>

          <TestiCrousel
            DataSet={testimonial_all}
            bg={false}
            headerPosition="left"
          />

          <div
            className="idigizen-solution-no-band"
            style={{
              width: "100vw",
              height: "100px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <HashLink to="pastevents/#testimonials" smooth>
              <IonButton
                shape="round"
                color={"tertiary"}
                className="listing_btn"
              >
                View All
              </IonButton>
            </HashLink>
          </div>

          <div id="team"></div>
          <TheTeam noText={false} />
          <Footer />
        </IonContent>
      </IonPage>
    </>
  );
};

export default IZPreneur;
