import { IonCol, IonGrid, IonRow } from "@ionic/react";
import React from "react";
import { useAppSelector } from "../app/hooks";
import { FeatureCardDataType, FeatureDataType } from "../data/data";
import "./styles/FeatureCard.css";

const FeatureCard: React.FC<{
  data: FeatureCardDataType;
}> = (props) => {
  let screenType: "PC" | "Tab" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });

  if (widthObj.width > 1350) screenType = "PC";
  else if (widthObj.width > 700) screenType = "Tab";
  else screenType = "Mobile";

  const size = screenType === "Mobile" ? "12" : "6";

  const topCss =
    screenType !== "Mobile" ? "feature-card-top" : "feature-card-top-mob";

  return (
    <div className={topCss}>
      <div className="feature-card-text">
        <div>
          <div className="feature-card-header">{props.data.header}</div>
          <div
            className={
              screenType === "Mobile"
                ? "feature-card-header-text-mob"
                : "feature-card-header-text"
            }
          >
            {props.data.text}
          </div>
        </div>
        <div className="feature-card-steps">
          <div
            className={
              screenType === "Mobile"
                ? "feature-card-steps-top-header-mob"
                : "feature-card-steps-top-header"
            }
          >
            {props.data.subheader}
          </div>

          <>
            {!props.data.isGrid &&
              props.data.steps.map((el, idx) => {
                return (
                  <div className="feature-card-step-card" key={idx}>
                    <div className="feature-card-step-header">{el.header}</div>
                    <div className="feature-card-step-text">{el.text}</div>
                  </div>
                );
              })}
          </>
          {props.data.isGrid && (
            <IonGrid>
              <IonRow>
                {props.data.steps.map((el, idx) => {
                  return (
                    <IonCol size={size} key={idx}>
                      <div className="feature-card-step-card" key={idx}>
                        <div className="feature-card-step-header">
                          {el.header}
                        </div>
                        <div className="feature-card-step-text">{el.text}</div>
                      </div>
                    </IonCol>
                  );
                })}
              </IonRow>
            </IonGrid>
          )}
        </div>
      </div>
      {!!props.data.imageName && (
        <div
          className={
            screenType === "Mobile"
              ? "feature-card-img-wrpr-mob"
              : "feature-card-img-wrpr"
          }
        >
          <img
            src={`assets/images/${props.data.imageName}`}
            alt=""
            className={
              screenType === "Mobile"
                ? "feature-card-img-mob"
                : "feature-card-img"
            }
          />
        </div>
      )}
    </div>
  );
};

export default FeatureCard;
