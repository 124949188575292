import { keyExists } from "./commonFunctions";

/************************************************************
 * Name: storeSessionDetails
 * Description: Initialization function to store user cookies
 *              based on successful login
 * Arguments: userData returned on login
 ************************************************************/
function storeSessionDetails(userData) {
  const sessionDetailKeys = [
    "token",
    "email",
    "firstname",
    "lastname",
    "id",
    "role",
    "profileimage",
    "schoolId",
  ];

  //store the session details in the localStorage for reference later.
  sessionDetailKeys.map((key) => {
    if (keyExists(key, userData)) localStorage.setItem(`${key}`, userData[key]);
    return "";
  });
}

/************************************************************
 * Name: removeSessionDetails
 * Description: Deletion function to remove user cookies
 *              based on successful logout
 * Arguments: userData returned on login
 ************************************************************/
function removeSessionDetails() {
  const sessionDetailKeys = [
    "token",
    "email",
    "firstname",
    "lastname",
    "id",
    "role",
    "profileimage",
    "schoolId",
  ];

  //store the session details in the localStorage for reference later.
  sessionDetailKeys.map((key) => {
    localStorage.removeItem(`${key}`);
    return "";
  });
}

/************************************************************
 * Name: getSessionDetails
 * Description: Function to retrieve all the stored session
 *              details and return it as an object.
 ************************************************************/
function getSessionDetails() {
  const sessionCookies = { ...localStorage };
  return sessionCookies;
}

export { storeSessionDetails, getSessionDetails, removeSessionDetails };
