interface TestimonialDataType {
  name: string;
  comment: string;
  designation: string;
  img?: string;
  video?: string;
  type?: string;
}

interface StoreListDataType {
  name: string;
  domaine: string;
  tagline: string;
  discription: string;
  logo: string;
  type?: string;
}
export const testimonial_kid: TestimonialDataType[] = [
  {
    name: "Sia Kedia",
    comment:
      "I feel empowered after the workshop. Amit and Jishnu sir helped discover the entrepreneur in me. Presenting to the sharks at the event was the highlight for me and  their appreciation for my pitch was the cherry on top ",
    designation: " Student",
    img: "/assets/images/testikid1.png",
    type: "Students Testimonials",
  },
  {
    name: "Kashika Gupta",
    comment:
      " The course was really fun, it taught me about techniques which I had never heard of. Starting my own business at the starting sounded really boring but later as I loved making the online business store and executing it.",
    designation: " Student",
    img: "/assets/images/testikid2.png",
    type: "Students Testimonials",
  },
  {
    name: "Anya Manohar",
    comment:
      "The business workshop was an incredible experience. It was educational and fun. It exceeded my expectations, providing practical strategies and valuable insights. I highly recommend it to everyone in my contact.",
    designation: " Student",
    img: "/assets/images/testikid3.png",
    type: "Students Testimonials ",
  },
];

export const testimonial_Parents = [
  {
    name: "Ms. Preeti",
    comment:
      "It is very tough to get Nonza to get excited about something but iZPreneur has been an outstanding program where he wants to come even if he is not feeling well. I would like to thank Idigizen to avail this kind of opportunity for our kids",
    designation: "Parent",
    type: "Parents Testimonials",
  },
  {
    name: "Ms. Pooja",
    comment:
      "Exhilarating experience - immaculately planned, meticulously executed and such a passionate team behind the scene. Kudos to Amit, Jishnu and Anubha for offering a perfect balance of fun and learning for my daughter.",
    designation: "Parent",
    type: "Parents Testimonials",
  },
  {
    name: "Dr. Archana",
    comment:
      "I am very happy that my daughter is learning a very new and innovative thing. Thanks to the Idigizen team because they help my child a lot. Essentially my child was apprehensive but as she did more classes she became very confident.",
    designation: "Parent",
    type: "Parents Testimonials  ",
  },
];

export const testimonial_judges: TestimonialDataType[] = [
  {
    name: "Mr. Neti Srinivasan",
    comment:
      "It was quite an extraordinary experience judging these young entrepreneurs. The passion and conviction with which they backed up their projects was remarkable. I was impressed by the inventiveness, the quality of research and the energy of the children.",
    designation: "Startup Advisor & Mentor",
    type: "Judges Testimonial",
  },
  {
    name: "Ms. Manu Gupta",
    comment:
      "It was a very well organized event. Kids pitched their ideas which were connected to their passion. We met budding bakers, poets, artists, pet-carers, Techpreneurs, sports enthusiasts and many more on this stage. As judges we had a difficult task at hand Overall.",
    designation: "Ex-CTO & Entrepreneur",
    type: "judges Testimonial",
  },
  {
    name: "Mr. Gurudutt Bansal",
    comment:
      "Seeing how children aligned their interests and hobbies with their passion for becoming young entrepreneurs was fascinating. The creativity and research in their projects, understanding the basics of business, executing it with an online store, and presenting it confidently.",
    designation: "Ex-VP Engineering",
    type: "judges Testimonial ",
  },
];

export const testimonial_all: TestimonialDataType[] = [
  {
    name: "Sia Kedia",
    comment:
      "I feel empowered after the workshop. Amit and Jishnu sir helped discover the entrepreneur in me. Presenting to the sharks at the event was the highlight for me and  their appreciation for my pitch was the cherry on top ",
    designation: " Student",

    type: "Testimonials",
  },
  {
    name: "Ms. Preeti",
    comment:
      "It is very tough to get Nonza to get excited about something but iZPreneur has been an outstanding program where he wants to come even if he is not feeling well.",
    designation: "Parent",
    type: "Testimonial",
  },
  {
    name: "Mr. Gurudutt Bansal",
    comment:
      "Seeing how children aligned their interests and hobbies with their passion for becoming young entrepreneurs was fascinating. Incredible creativity and research and executing with conviction.",
    designation: "Ex-VP Engineering",
    type: "Testimonial",
  },
];

export const StoreData: StoreListDataType[] = [
  {
    name: "sporty team 1",
    domaine: "sportyteam1",
    tagline: "one team one dream",
    discription:
      "This is a place where you can learn different type of sports but for now i am teaching only basketball ..",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//101892023-06-01:05:20:22_2.jpeg&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "Stores",
  },
  {
    name: "your woof is our command",
    domaine: "yourwoofisourcommand",
    tagline: "Paws-itively passionate about pets!",

    discription:
      "Love is four legged word. We provide caring hands for paws and make sure all pets reach their full paw-tential without any ruff times.",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//102122023-06-02:00:10:38_3.jpeg&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "student",
  },

  {
    name: "eco gadget",
    domaine: "eco-gadget",
    tagline: "Transforming Waste to Wonders for your Devices",

    discription:
      "We are saving the environment by using recyclable substances to make our accessories for gadgets and devices like mobile phones and much more coming soon.",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//102072023-05-29:07:19:51_9.png&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "student",
  },

  {
    name: "rhymeit",
    domaine: "rhymeit",
    tagline: "Express the unspeakable with just words and food",

    discription:
      "Get poems in a frame, poster, online, canvas etc, any way you would want it.",
    logo: "/assets/images/ilogo.png",
    type: "student",
  },
  {
    name: "oven to table",
    domaine: "oventotable",
    tagline: "Made with Passion,Selled with Love",

    discription:
      "This company is all about baking.I will sell many delicious goddies.And you can customise your own cupcakes and cookies with the DIY kits.",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//102142023-05-29:16:12:56_9.png&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "student",
  },
  {
    name: "wild beauty",
    domaine: "wildbeauty",
    tagline: "Why to be simple? when you can be WILD!",

    discription: "This brand sells skin safe products! FIND YOUR COLOUR!.",
    logo: "/assets/images/ilogo.png",
    type: "student",
  },
  {
    name: "scotch tales",
    domaine: "scotch-tales",
    tagline: "Each Tail has a Tale to Tell",

    discription:
      "Scotch Tales is a business which has a bakery selling cakes, cupcakes, treats etc.",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//102112023-05-29:07:12:47_c.jpeg&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "student",
  },
  {
    name: "gifts raid",
    domaine: "giftsraid",
    tagline: "Gread Gifts And Cards For Great People",

    discription:
      "This enterprise sells personalised gifts, cards and drawings. ",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//101882023-06-01:07:14:12_2.jpeg&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "student",
  },
  {
    name: "MacGraphy",
    domaine: "MacGraphy",
    tagline: "You Dream, We Create",

    discription:
      "MacGraphy is a company that sells various universal and personalized products using calligraphy and macrame techniques.",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//101862023-05-26:11:13:57_c.jpeg&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "student",
  },
  {
    name: "Melting Momentz",
    tagline: "",
    discription:
      "This is a business specializing in selling decorated cakes but is also a bakery selling cookies, cupcakes, etc..",
    domaine: "MeltingMomentz",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//101942023-05-31:04:45:32_d.jpeg&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "Stores",
  },
  {
    name: "Eclipse",
    domaine: "Eclipse",
    tagline: "Embrace your inner drip and darkness",
    discription:
      "This business invites all drip lovers who wish to bring their originality to life in the way they dress",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//102622023-06-26:04:51:57_6.jpeg&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "Stores",
  },
  {
    name: "Vats",
    domaine: "Vats",
    tagline: "Just customise",
    discription:
      "We have a very unique business where we customise sports equipment.",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//103172023-06-24:12:46:02_f.png&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "Stores",
  },
  {
    name: "bibliomaniac",
    domaine: "bibliomaniac",
    tagline: "the cure for your book hangovers",
    discription:
      "Relive reading your favourite books for the first time. You can enter your favourite book,series or genre and you will recieve recommendations similar to your favourite book to get you over your book hangover.",
    logo: "/assets/images/ilogo.png",
    type: "Stores",
  },
  {
    name: "mastermind creation",
    domaine: "mastermind",
    tagline: "magic in every creation",
    discription:
      "We help to create your custom posters and stickers in record time",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//103082023-06-24:12:49:21_9.png&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "Stores",
  },
  {
    name: "Real Champions",
    domaine: "realchampions",
    tagline: "TURNING KIDS INTO CHAMPIONS SINCE 2023",
    discription:
      "Real Champions is an academy for kids who are in KG to 3rd grade to learn football with their parents as a bonding exercise.",
    logo: "/assets/images/ilogo.png",
    type: "Stores",
  },
  {
    name: "Gamer Koala",
    domaine: "GamerKoala",
    tagline: "Read Game Sport Repeat",
    discription:
      "We are a company that allows you to experience the product and not purchase it. You can rent or rent out stuff which you always wanted to experience, but didnt want to buy because it was not worth it. We provide renting of books, video games, and sporting equipments.",
    logo: "https://api.newprod.idigizen.com/getThumbnail?fileName=/mnt/asset.idigizen.com/files/upload//102192023-06-13:06:15:53_b.png&percentage=40&Authorization=8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2&",
    type: "Stores",
  },
];

export type { TestimonialDataType };
