import React, { useEffect, useState } from "react";
import { useAppSelector } from "../app/hooks";
import { getScreenType } from "../data/breakpoints";
import {
  API_SECURE_KEY,
  IDIGICATOR_URL,
  SCHOOLREG_FORM_ID,
} from "../data/constants";

const WorkForPassionBanner: React.FC<{
  kidSubheader: string;
  kidHeader: string;
  adultSubheader: string;
  adultHeader: string;
  hashtag: string;
  buttonName: string;
  onButtonClick?: () => void;
  onButtonClickLink?: string;
}> = (props) => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);
  const [mobPicIndex, setMobPicIndex] = useState<number>(0);
  const [showKids, setShowKids] = useState<boolean>(true);
  const [kidsAnimationCss, setKidsAnimationCss] = useState<string>(
    "idigizen-solution-banner-visible"
  );
  const [adultsAnimationCss, setAdultsAnimationCss] = useState<string>(
    "idigizen-solution-banner-invisible"
  );

  const pictureMobSlideKid = [
    "sports_kid.png",
    "doctor_kid.png",
    "chef_kid.png",
    "engineer_kid.png",
    "painter_kid.png",
    "singer_kid.png",
  ];

  const pictureMobSlideAdult = [
    "sports_adult.png",
    "doctor_adult.png",
    "chef_adult.png",
    "engineer_adult.png",
    "painter_adult.png",
    "singer_adult.png",
  ];

  const getTime = (): string => {
    // Get the current time
    const now = new Date();

    // Extract the minutes and seconds
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    // Format the minutes and seconds as two-digit numbers
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    // Display the time in mm:ss format
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  useEffect(() => {
    let intervalId;
    const animationLoop = () => {
      //   console.log("Start Interval", getTime());
      // Fade out kids
      setTimeout(() => {
        // console.log("Fade Out Kids ", getTime());
        setKidsAnimationCss("idigizen-solution-banner-invisible");
      }, 3000);
      // Remove kids and fade in adult
      setTimeout(() => {
        // console.log("Fade In Adult", getTime());
        setShowKids(false);
      }, 3500);
      // Remove kids and fade in adult
      setTimeout(() => {
        // console.log("Fade In Adult", getTime());
        setAdultsAnimationCss("idigizen-solution-banner-visible");
      }, 3600);
      // Fade out adults
      setTimeout(() => {
        // console.log("Fade Out Adult", getTime());
        setAdultsAnimationCss("idigizen-solution-banner-invisible");
      }, 6500);
      // Remove Adults and fade in kids
      setTimeout(() => {
        // console.log("Fade In Kids", getTime());
        setShowKids(true);
        setMobPicIndex((prev) => {
          if (prev === 5) return 0;
          else return prev + 1;
        });
      }, 7000);
      setTimeout(() => {
        // console.log("Fade In Kids", getTime());
        setKidsAnimationCss("idigizen-solution-banner-visible");
      }, 7100);
    };
    intervalId = setInterval(() => {
      animationLoop();
    }, 7000);
    animationLoop();
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const homePageBannerPCJsx = (
    <div className="idigizen-solution-banner-wrpr">
      <div className="idigizen-solution-top"></div>
      <div className="idigizen-solution-banner-border"></div>
      <div className="idigizen-solution-banner-overlaycard">
        {showKids && (
          <img
            className={`idigizen-solution-banner-img ${kidsAnimationCss}`}
            src="assets/images/banner_kids.png"
            alt="The varied interests and hobbies of students"
          />
        )}
        {!showKids && (
          <img
            className={`idigizen-solution-banner-img ${adultsAnimationCss}`}
            src="assets/images/banner_adults.png"
            alt="Successful careers built connecting interests and passions to career"
          />
        )}
        <div className="idigizen-solution-banner-header-banner">
          {showKids && (
            <div className={kidsAnimationCss}>
              <div
                className={
                  screenType === "PC"
                    ? "idigizen-solution-banner-subheader"
                    : "idigizen-solution-banner-subheader-tab"
                }
              >
                {props.kidSubheader}
              </div>
              <div
                className={
                  screenType === "PC"
                    ? "idigizen-solution-banner-header"
                    : "idigizen-solution-banner-header-tab"
                }
              >
                {props.kidHeader}
              </div>
            </div>
          )}
          {!showKids && (
            <div className={adultsAnimationCss}>
              <div
                className={
                  screenType === "PC"
                    ? "idigizen-solution-banner-subheader"
                    : "idigizen-solution-banner-subheader-tab"
                }
              >
                {props.adultSubheader}
              </div>
              <div
                className={
                  screenType === "PC"
                    ? "idigizen-solution-banner-header"
                    : "idigizen-solution-banner-header-tab"
                }
              >
                {props.adultHeader}
              </div>
            </div>
          )}
          <div
            className="idigizen-solution-banner-button"
            onClick={() => {
              if (props.onButtonClick) {
                props.onButtonClick();
                return;
              }
              const link = props.onButtonClickLink
                ? props.onButtonClickLink
                : `${IDIGICATOR_URL}forms?type=Form&id=${SCHOOLREG_FORM_ID}&Authorization=${API_SECURE_KEY}&submitOnly=true`;
              window.location.assign(link);
            }}
          >
            {props.buttonName}
          </div>
        </div>
        <div className="idigizen-solution-banner-wfp">
          <div>
            <div
              className={
                screenType === "PC"
                  ? "idigizen-solution-banner-wfp-text"
                  : "idigizen-solution-banner-wfp-text-tab"
              }
            >
              #{props.hashtag}
            </div>
            {/* <div className="idigizen-solution-banner-wfp-text">for</div> */}
            {/* <div className="idigizen-solution-banner-wfp-text">Passion</div> */}
          </div>
        </div>
      </div>
    </div>
  );

  const homePageBannerMobJsx = (
    <div className="idigizen-solution-banner-wrpr">
      <div className="idigizen-solution-top-mob"></div>
      <div className="idigizen-solution-banner-border"></div>
      <div className="idigizen-solution-banner-overlaycard">
        <div className="idigizen-solution-banner-wfp-mob">#{props.hashtag}</div>
        <div className="idigizen-solution-banner-header-banner-mob">
          {showKids && (
            <div className={kidsAnimationCss}>
              <div className="idigizen-solution-banner-subheader-mob ">
                {props.kidSubheader}
              </div>
              <div className="idigizen-solution-banner-header-mob ">
                {props.kidHeader}
              </div>
            </div>
          )}
          {!showKids && (
            <div className={adultsAnimationCss}>
              <div className="idigizen-solution-banner-subheader-mob">
                {props.adultSubheader}
              </div>
              <div className="idigizen-solution-banner-header-mob">
                {props.adultHeader}
              </div>
            </div>
          )}
          <div
            className="idigizen-solution-banner-button"
            onClick={() => {
              if (props.onButtonClick) {
                props.onButtonClick();
                return;
              }
              window.location.assign(
                `${IDIGICATOR_URL}forms?type=Form&id=${SCHOOLREG_FORM_ID}&Authorization=${API_SECURE_KEY}&submitOnly=true`
              );
            }}
          >
            {props.buttonName}
          </div>
        </div>
        {showKids && (
          <img
            className={`idigizen-solution-banner-img ${kidsAnimationCss}`}
            src={`assets/images/${pictureMobSlideKid[mobPicIndex]}`}
            alt="The varied interests and hobbies of students"
          />
        )}
        {!showKids && (
          <img
            className={`idigizen-solution-banner-img ${adultsAnimationCss}`}
            src={`assets/images/${pictureMobSlideAdult[mobPicIndex]}`}
            alt="Successful careers built connecting interests and passions to career"
          />
        )}
      </div>
    </div>
  );
  return (
    <>
      {(screenType === "PC" || screenType === "Tab") && homePageBannerPCJsx}
      {screenType === "Mobile" && homePageBannerMobJsx}
    </>
  );
};

export default WorkForPassionBanner;
