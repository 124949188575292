import { IonContent, IonPage } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { useAppDispatch } from "../app/hooks";
import AboutUs from "../components/AboutUs";
import ErpOverview from "../components/ErpOverview";
import Features from "../components/Features";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Pricing from "../components/Pricing";
import StakeholderSection from "../components/StakeholderSection";
import SubmitForm from "../components/SubmitForm";
import { navLinkIDs } from "../data/data";
import { headerOffsetActions } from "../store/header-offset-slice";
import ContentWithBackground from "../UI/ContentWithBackground";

const Home: React.FC = () => {
  const navRefs = useRef({});
  const contentRef = useRef(null);
  const dispatch = useAppDispatch();

  const scrollToPoint = (id: string) => {
    const elRef = navRefs.current[id];
    const offset =
      elRef.current.offsetTop - navRefs.current["header"].current.clientHeight;
    console.log(
      "Called ref ",
      offset,
      elRef.current.offsetHeight,
      navRefs.current["header"].clientHeight,
      navRefs
    );
    contentRef.current.scrollToPoint(0, offset, 500);
  };

  const scrollToTop = () => {
    contentRef.current.scrollToTop(500);
  };

  useEffect(() => {
    const funcID = setTimeout(() => {
      console.log(
        navRefs,
        "height ",
        navRefs.current["header"].current.clientHeight
      );
      if (navRefs.current && navRefs.current["header"]) {
        console.log(
          "set offset height ",
          navRefs.current["header"].current.clientHeight
        );
        dispatch(
          headerOffsetActions.update({
            offset: navRefs.current["header"].current.clientHeight,
          })
        );
      }
    }, 500);
    return () => {
      clearTimeout(funcID);
    };
  }, [navRefs]);

  return (
    <>
      <SubmitForm />
      <IonPage>
        <IonContent ref={contentRef}>
          <Header
            navref={navRefs}
            scrollToPoint={scrollToPoint}
            scrollToTop={scrollToTop}
          />
          <ErpOverview />
          <StakeholderSection
            navRef={{ id: navLinkIDs.solution, ref: navRefs }}
          />
          <Features navRef={{ id: navLinkIDs.features, ref: navRefs }} />
          <Pricing navRef={{ id: navLinkIDs.pricing, ref: navRefs }} />
          <AboutUs navRef={{ id: navLinkIDs.aboutus, ref: navRefs }} />
          <Footer />
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
