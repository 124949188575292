import React from "react";
import { useAppSelector } from "../app/hooks";
import { getScreenType } from "../data/breakpoints";
import { IonButton } from "@ionic/react";
import { useHistory } from "react-router";

const IDigizenFeaturesCard: React.FC<{
  headerPC: string[];
  headerMob: string[];
  img: { path: string; text: string };
  bulletText: string[];
  button?: { name: string; onClick: () => void };
}> = (props) => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);
  const history = useHistory();

  const appOverviewPCJsx = (
    <div className="idigizen-solution-app-banner-top">
      <div className="idigizen-solution-app-banner-img-section">
        <img
          src={props.img.path}
          alt={props.img.text}
          className="idigizen-solution-app-banner-img"
        />
        <div className="idigizen-solution-app-banner-header">
          {props.headerPC.map((el) => {
            return <div>{el}</div>;
          })}
        </div>
      </div>

      <div className="idigizen-solution-app-banner-text">
        <ul style={{ listStyle: "square" }}>
          {props.bulletText.map((el) => {
            return (
              <li className="idigizen-solution-app-banner-text-bullet">{el}</li>
            );
          })}
          {props.button && (
            <div>
              <IonButton color={"light"} onClick={props.button.onClick}>
                {props.button.name}
              </IonButton>
            </div>
          )}
        </ul>
      </div>
    </div>
  );

  const appOverviewMobJsx = (
    <div className="idigizen-solution-app-banner-top-mob">
      <div className="idigizen-solution-app-banner-header-mob">
        {props.headerMob.map((el) => {
          return <div>{el}</div>;
        })}
      </div>
      <img
        src={props.img.path}
        alt={props.img.text}
        className="idigizen-solution-app-banner-img-mob"
      />

      <div className="idigizen-solution-app-banner-text-mob">
        <ul style={{ listStyle: "square" }}>
          {props.bulletText.map((el) => {
            return (
              <li className="idigizen-solution-app-banner-text-bullet-mob">
                {el}
              </li>
            );
          })}
        </ul>
      </div>
      {props.button && (
        <div>
          <IonButton color={"light"} onClick={props.button.onClick}>
            {props.button.name}
          </IonButton>
        </div>
      )}
    </div>
  );

  return (
    <>
      {screenType === "PC" && appOverviewPCJsx}
      {screenType === "Tab" && appOverviewMobJsx}
      {screenType === "Mobile" && appOverviewMobJsx}
    </>
  );
};

export default IDigizenFeaturesCard;
