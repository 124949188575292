import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { close } from "ionicons/icons";
import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { formFillerDesignation } from "../data/data";
import { formModalOpenActions } from "../store/form-modal-open-slice";
import "./styles/SubmitForm.css";

const SubmitForm: React.FC = () => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phNum, setPhNum] = useState<number>(-1);
  const [role, setRole] = useState<string>("");
  const [freeTextRole, setFreeTextRole] = useState<string>("");
  const [instituteName, setInstituteName] = useState<string>("");

  const resetStates = () => {
    setName("");
    setEmail("");
    setPhNum(-1);
    setRole("");
    setFreeTextRole("");
    setInstituteName("");
  };
  const open: boolean = useAppSelector((state) => {
    return state.formModalOpen.open;
  });
  const dispatch = useAppDispatch();

  const onDismiss = () => {
    resetStates();
    dispatch(formModalOpenActions.update({ open: false }));
  };

  return (
    <IonModal isOpen={open} onDidDismiss={onDismiss}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Holistic Ecosystem
            <div>a click away!</div>
          </IonTitle>
          <IonButtons slot="end">
            <IonButton color={"danger"} fill="clear" onClick={onDismiss}>
              <IonIcon icon={close} />
              Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="submit-form-top">
          <div className="submit-form-img-wrpr">
            <img
              src="assets/images/iDigizen_Logo.png"
              className="submit-form-img"
              alt=""
            />
          </div>
          <div className="submit-form-wrpr">
            <div className="submit-form">
              <div className="submit-form-header"></div>
              <IonItem>
                <IonLabel position="floating">School/ Institute Name</IonLabel>
                <IonInput
                  value={instituteName}
                  onIonChange={(e) => setInstituteName(e.detail.value)}
                />
              </IonItem>
              <IonItem>
                <IonLabel position="floating">Name</IonLabel>
                <IonInput
                  value={name}
                  onIonChange={(e) => setName(e.detail.value)}
                />
              </IonItem>
              <div className="submit-form-clubber">
                <IonItem className="submit-form-clubber-el">
                  <IonLabel position="floating">Email</IonLabel>
                  <IonInput
                    value={email}
                    onIonChange={(e) => setEmail(e.detail.value)}
                  />
                </IonItem>
                <IonItem className="submit-form-clubber-el">
                  <IonLabel position="floating">10 digit Phone</IonLabel>
                  <IonInput
                    type="number"
                    value={phNum < 0 ? "" : phNum}
                    onIonChange={(e) => {
                      setPhNum(parseInt(e.detail.value));
                    }}
                  />
                </IonItem>
              </div>
              <div className="submit-form-clubber">
                <IonItem className="submit-form-clubber-el">
                  <IonLabel>Select Role</IonLabel>
                  <IonSelect
                    interface="popover"
                    onIonChange={(e) => {
                      setRole(e.detail.value);
                    }}
                  >
                    {formFillerDesignation.map((el, idx) => {
                      return (
                        <IonSelectOption key={idx} value={el}>
                          {el}
                        </IonSelectOption>
                      );
                    })}
                  </IonSelect>
                </IonItem>
                <IonItem className="submit-form-clubber-el">
                  <IonLabel>Specify Role</IonLabel>
                  <IonInput
                    disabled={!(role === "None of the above")}
                    value={freeTextRole}
                    onIonChange={(e) => {
                      setFreeTextRole(e.detail.value);
                    }}
                  />
                </IonItem>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
      <IonFooter>
        <IonButton fill="solid" color={"secondary"} expand="block">
          Submit
        </IonButton>
      </IonFooter>
    </IonModal>
  );
};

export default SubmitForm;
