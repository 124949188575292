import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { offset: number } = { offset: 0 };

const headerOffsetSlice = createSlice({
  name: "header-offset-slice",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<{ offset: number }>) => {
      state.offset = action.payload.offset;
    },
  },
});

export const headerOffsetActions = headerOffsetSlice.actions;
export default headerOffsetSlice;
