import React from "react";
import { useAppSelector } from "../app/hooks";
import { getScreenType } from "../data/breakpoints";
import IDigizenHeaderBandCard from "../UI/IDigizenHeaderBandCard";
import { IonCol, IonGrid, IonRow } from "@ionic/react";

const IDigizenTeamCard: React.FC<{
  img: string;
  position: string;
  name: string;
  text: string[];
  noBorder: boolean;
}> = (props) => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);
  const border = "1px solid var(--ion-color-primary-blue)";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px",
        borderRight: !props.noBorder && screenType !== "Mobile" ? border : "",
        borderBottom: !props.noBorder && screenType === "Mobile" ? border : "",
      }}
    >
      <div>
        <div
          style={{
            height: "125px",
            width: "125px",
            border: "4px solid var(--ion-color-secondary)",
            borderRadius: "50%",
            overflow: "hidden",
          }}
        >
          <img
            src={props.img}
            alt=""
            style={{ objectFit: "scale-down", height: "100%", width: "100%" }}
          />
        </div>
        <div style={{ textAlign: "center", marginTop: "10px" }}>
          <div
            style={{
              fontSize: "large",
              fontWeight: "bold",
              color: "var(--ion-color-banner-navy-blue)",
            }}
          >
            {props.name}
          </div>
          <div style={{ fontWeight: "bold", color: "var(--ion-color-medium)" }}>
            {props.position}
          </div>
        </div>
      </div>
      <div style={{ padding: "10px", textAlign: "right" }}>
        {props.text.map((el) => {
          return <div>{el}</div>;
        })}
      </div>
    </div>
  );
};

const TheTeam: React.FC<{ noText: boolean }> = (props) => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);
  const teamText = {
    intro:
      "Team iDigizen has meticulously crafted this workshop that aims to bridge the gap between world of education and the industry. The workshop aims to provide the participants with invaluable insights and skills that will help them become successful professionals.",
    team: [
      {
        img: "assets/images/Amit.jpeg",
        name: "Amit Garg",
        position: "Founder",
        text: [
          "Ex-Scientist & Chief Architect",
          "Multiple International Patents",
          "Multiple International Publications",
          "25+Years Industry Experience",
        ],
      },
      {
        img: "assets/images/Jishnu.jpeg",
        name: "Jishnu De",
        position: "Co-Founder",
        text: [
          "Youngest Senior Staff & Architect",
          "Multiple International Patents",
          "Multiple International Publications",
          "12+Years Industry Experience",
        ],
      },
      {
        img: "assets/images/Neha.jpeg",
        name: "Neha Wahi",
        position: "Career Counselor",
        text: [
          "Psychologist & Author",
          "Career Guidance Expert",
          "Parent Coach & Teacher Trainer",
          "18+Years Industry Experience",
        ],
      },
    ],
  };

  return (
    <IDigizenHeaderBandCard
      bgColor={true}
      header="Meet The Team"
      headerPosition="right"
    >
      {!props.noText && (
        <div style={{ fontSize: "large" }}>{teamText.intro}</div>
      )}
      <IonGrid>
        <IonRow>
          {teamText.team.map((el, idx) => {
            return (
              <IonCol size={screenType === "Mobile" ? "12" : "4"}>
                <IDigizenTeamCard
                  img={el.img}
                  name={el.name}
                  position={el.position}
                  text={el.text}
                  noBorder={idx === teamText.team.length - 1}
                />
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
    </IDigizenHeaderBandCard>
  );
};

export default TheTeam;
