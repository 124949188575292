interface pastEventCardDataType {
  number: string;
  text: string;
}

export const pastEventCard: pastEventCardDataType[] = [
  {
    number: "20+",
    text: "Business Launches",
  },
  {
    number: "30+",
    text: "Business Transactions",
  },
  {
    number: "5+",
    text: "Business Collaborations",
  },
];

export type { pastEventCardDataType };
