import {
  IonButton,
  IonHeader,
  IonIcon,
  IonImg,
  IonItem,
  IonList,
  IonListHeader,
  IonPopover,
} from "@ionic/react";
import {
  bookmark,
  bookmarkOutline,
  bookmarks,
  bookmarkSharp,
  menuOutline,
} from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../app/hooks";
import { IDIGICATOR_URL, IDIGIZEN_COURSES_URL } from "../data/constants";
import { navLinkIDs } from "../data/data";
import screenWidthSlice from "../store/screen-width-slice";
import FormButton from "./FormButton";
import "./styles/Header.css";
import { useHistory } from "react-router";

const Header: React.FC<{
  navref?: any;
  scrollToPoint?: (id: string) => void;
  scrollToTop?: () => void;
  actionButtonName?: string;
  onActionButtonClick?: () => void;
  buttons?: { name: string; onClick: () => void }[];
  onClickLink?: string;
}> = (props) => {
  const localref = useRef(null);
  useEffect(() => {
    if (localref && props.navref) props.navref.current["header"] = localref;
  }, [localref]);

  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const history = useHistory();

  const clickHandler = (navId) => {
    if (!props.scrollToPoint) return;
    props.scrollToPoint(navId);
    setOpenDrawer(false);
  };

  const loginHandler = () => {
    window.location.assign(IDIGICATOR_URL);
  };

  const coursesPageHandler = () => {
    window.location.assign(IDIGIZEN_COURSES_URL);
  };

  const pcModeButtonsJsx = (
    <div>
      <div className="header-toolbar">
        <div className="header-buttons">
          {props.buttons &&
            props.buttons.map((el) => {
              return (
                <IonButton fill="clear" color={"dark"} onClick={el.onClick}>
                  {el.name}
                </IonButton>
              );
            })}
          <IonButton color={"secondary"} fill="clear" onClick={loginHandler}>
            Login
          </IonButton>

          <FormButton
            name={
              !props.actionButtonName ? "Get Started" : props.actionButtonName
            }
            color="secondary"
            onClick={props.onActionButtonClick}
            onClickLink={props?.onClickLink}
          />
        </div>
      </div>
    </div>
  );

  const isPCMode = widthObj.width > 950;

  const mobileModeButtonsJsx = (
    <div className="header-mobile-buttons">
      {/* <div id="header-bookmark-id">
        <IonButton
          fill="clear"
          color={"dark"}
          onClick={() => {
            setOpenSections((val) => !val);
          }}
        >
          <IonIcon icon={bookmarkOutline} size="large" />
        </IonButton>
        <IonPopover
          side="bottom"
          alignment="end"
          isOpen={openSections}
          onDidDismiss={() => {
            setOpenSections(false);
          }}
          trigger={"header-bookmark-id"}
        ></IonPopover>
      </div> */}
      <div id="header-menu-id">
        <IonButton
          fill="clear"
          color={"dark"}
          onClick={() => {
            setOpenDrawer((val) => !val);
          }}
        >
          <IonIcon icon={menuOutline} size="large" />
        </IonButton>
        <IonPopover
          side="bottom"
          alignment="end"
          isOpen={openDrawer}
          onDidDismiss={() => {
            setOpenDrawer(false);
          }}
          trigger={"header-menu-id"}
        >
          <FormButton
            name={
              !props.actionButtonName ? "Get Started" : props.actionButtonName
            }
            onClick={props.onActionButtonClick}
            color="primary"
            fill="clear"
          />

          {props.buttons &&
            props.buttons.map((el) => {
              return (
                <IonButton fill="clear" color={"dark"} onClick={el.onClick}>
                  {el.name}
                </IonButton>
              );
            })}
          <IonButton
            color={"secondary"}
            fill="clear"
            expand="full"
            onClick={loginHandler}
          >
            Login
          </IonButton>
          <IonList>
            {/* <IonItem color={"light"}>
              <IonIcon icon={bookmarkOutline} /> Bookmarks
            </IonItem>
            <IonItem
              onClick={() => {
                props.scrollToTop();
                setOpenDrawer(false);
              }}
            >
              Top
            </IonItem> */}
            {/* <IonItem
              onClick={() => {
                clickHandler(navLinkIDs.solution);
                setOpenDrawer(false);
              }}
            >
              Solution
            </IonItem>
            <IonItem
              onClick={() => {
                clickHandler(navLinkIDs.features);
                setOpenDrawer(false);
              }}
            >
              Features
            </IonItem>
            <IonItem
              onClick={() => {
                clickHandler(navLinkIDs.pricing);
                setOpenDrawer(false);
              }}
            >
              Pricing
            </IonItem>
            <IonItem
              onClick={() => {
                clickHandler(navLinkIDs.aboutus);
                setOpenDrawer(false);
              }}
            >
              About Us
            </IonItem> */}
          </IonList>
        </IonPopover>
      </div>
    </div>
  );

  const logoCss = isPCMode ? "header-logo" : "header-logo-mobile";
  return (
    <div className="header-top" ref={localref}>
      <div
        className={logoCss}
        onClick={() => {
          history.push("/");
        }}
      >
        <IonImg
          src="assets/images/iDigizen_Logo.png"
          className="header-logo-img"
        />
      </div>
      {isPCMode && pcModeButtonsJsx}
      {!isPCMode && mobileModeButtonsJsx}
    </div>
  );
};

export default Header;
