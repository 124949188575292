import { IonButton } from "@ionic/react";
import React from "react";
import { useHistory } from "react-router";
import { useAppDispatch } from "../app/hooks";
import {
  API_SECURE_KEY,
  IDIGICATOR_URL,
  SCHOOLREG_FORM_ID,
} from "../data/constants";
import { formModalOpenActions } from "../store/form-modal-open-slice";

const FormButton: React.FC<{
  name: string;
  className?: string;
  color?: string;
  fill?: "clear" | "solid" | "outline";
  isRound?: boolean;
  size?: "large" | "small" | "default";
  onClick?: () => void;
  onClickLink?: string;
}> = (props) => {
  const dispatch = useAppDispatch();
  const color = "color" in props ? props.color : "secondary";
  const fill = "fill" in props ? props.fill : "solid";
  const shape = "isRound" in props && props.isRound ? "round" : undefined;
  const size = "size" in props ? props.size : undefined;
  const history = useHistory();

  return (
    <IonButton
      color={color}
      fill={fill}
      shape={shape}
      expand="full"
      size={size}
      onClick={() => {
        // dispatch(formModalOpenActions.update({ open: true }));
        // history.push({
        //   pathname: `${IDIGICATOR_URL}forms`,
        //   search: `?type=Form&id=${SCHOOLREG_FORM_ID}`,
        // });
        if (props.onClick) {
          props.onClick();
          return;
        }
        const link = props.onClickLink
          ? props.onClickLink
          : `${IDIGICATOR_URL}forms?type=Form&id=${SCHOOLREG_FORM_ID}&Authorization=${API_SECURE_KEY}&submitOnly=true`;
        window.location.assign(link);
      }}
      className={props.className ? props.className : ""}
    >
      {props.name}
    </IonButton>
  );
};

export default FormButton;
