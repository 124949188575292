import React from "react";
import { useAppSelector } from "../app/hooks";
import { getScreenType } from "../data/breakpoints";

const IDigizenHeaderBandCard: React.FC<{
  headerPosition: "right" | "left";
  bgColor: boolean;
  header: string;
  children?: React.ReactNode;
}> = (props) => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);

  const pcJsx = (
    <div
      style={{ padding: "1em" }}
      className={`${
        props.bgColor
          ? "idigizen-solution-blue-band"
          : "idigizen-solution-no-band"
      } 
      `}
    >
      {!!props.header && (
        <div
          style={{ fontSize: "1.2em" }}
          className={`${
            props.headerPosition === "right"
              ? "idigizen-solution-pie-text-img-left"
              : "idigizen-solution-pie-text-img-right"
          }`}
        >
          <div
            className={`idigizen-solution-pie-hdr ${
              props.headerPosition === "right"
                ? "idigizen-solution-pie-hdr-img-left"
                : "idigizen-solution-pie-hdr-img-right"
            }        
        `}
          >
            {props.header}
          </div>
        </div>
      )}
      {props?.children}
    </div>
  );

  const mobileJsx = (
    <div
      className={`idigizen-solution-band-mob ${
        props.bgColor
          ? "idigizen-solution-blue-band"
          : "idigizen-solution-no-band"
      }`}
    >
      {!!props.header && (
        <div
          className={`idigizen-solution-pie-hdr-mob ${
            props.headerPosition === "right"
              ? "idigizen-solution-pie-hdr-img-left-mob"
              : "idigizen-solution-pie-hdr-img-right-mob"
          }        
        `}
        >
          {props.header}
        </div>
      )}
      {props?.children}
    </div>
  );

  return (
    <>
      {screenType === "PC" && pcJsx}
      {screenType === "Tab" && pcJsx}
      {screenType === "Mobile" && mobileJsx}
    </>
  );
};

export default IDigizenHeaderBandCard;
