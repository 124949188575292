// const backend_server = "http://localhost:8443";
const IDIGIZEN_BACKEND_URL = "https://api.newprod.idigizen.com";
//const IDIGIZEN_BACKEND_URL = "http://localhost:8443";
// const IDIGIZEN_BACKEND_URL = "http://10.5.50.97:8443";
// const IDIGIZEN_BACKEND_URL = "http://192.168.1.108:8443";
const API_SECURE_KEY =
  "8BEYKFmSROr0iUV36SjUUOu38ZDgifivsFU2SzbUKNTXUQY0b42LkveyQ0Y7t4BtypcMnvrjLJL4o5SUoaE9siUrfS3wSUi2";
//const backend_cert_server = "https://api.newprod.idigizen.com";
// const backend_cert_server = "http://localhost:8443";
// const backend_admin_route = "https://api.admin.idigizen.com";
// const backendprod_route = "https://api.idigizen.com";
const sqlPullStudentList =
  "select u.id as userId, u.firstname as studentName, u.standard, u.section, count(d.id) as numAccolades, u.phoneNumber from userdata as u inner join documents as d on d.userId = u.id where u.schoolId=25 group by u.id order by numAccolades desc";
// const sqlPullStudentAccolades =
//   "select concat (u.firstname, ' ', u.lastName) as studentName, concat (u.standard, ' ', u.section) as standard, d.docTitle, d.type, d.what, d.WHERE, d.WHEN, d.docDescription, d.fileName from userdata as u inner join documents as d on d.userId = u.id where u.id=6050";
const sqlPullStudentAccolades =
  "select concat (u.firstname, ' ', u.lastName) as studentName, concat (u.standard, ' ', u.section) as standard, d.docTitle, d.type, d.what, d.WHERE, d.WHEN, d.docDescription, d.fileName from userdata as u inner join documents as d on d.userId = u.id where u.id=%%ID%%";
//const backend_cert_server = "http://localhost:3033";
const userInfoRouter = "get-user-info";
const getBehaviorTraitsRouter = "get-behavior-class-sec-sess-exam-bundle";
const subClassSecSessExamBundleRouter = "get-sub-class-sec-sess-exam-bundle";
const subClassSecSessExamMarksSaveRouter =
  "save-sub-class-sec-sess-exam-bundle";
const subClassSecSessTraitsBundleSaveRouter =
  "save-sub-class-sec-sess-traits-bundle";
const subClassSecSessStudentTraitsBundleSaveRouter =
  "save-sub-class-sec-sess-student-traits-bundle";
const getBehaviorTraitsPerStudentRouter =
  "get-behavior-class-sec-sess-exam-student-bundle";
const getStudentListSessionClassSection = "get-class-sec-sess-student-list";
const getReportCardStudentListClassSection = "get-student-list-for-report-card";
const getSessPerStudentReport = "get-per-student-report";
const saveStudentAttendance = "student-attendance-save";
const getStudentReportCardComments = "get-student-report-card-comments-db";
const getReportCardComments = "get-report-card-comments-db";
const saveReportCardComments = "save-student-report-card-comments-db";
const deleteReportCardComments = "delete-student-report-card-comments-db";
const saveAnecdote = "save-student-anecdote-db";
const deleteAnecdote = "del-student-anecdote-db";
const getStudentAnecdoteList = "get-student-anecdote-db";
const getAnecdoteList = "get-anecdote-db";
const getEventListDB = "get-event-list";
const getEventInputDB = "get-event-input-db";
const getEventDetailsDB = "get-event-details";
const getEventDetailsForBusinessPage = "get-event-details-for-business-page";
const getFileThumbnailDB = "getThumbnail";
const updateEventStatusDB = "update-event-status";
const getStudentAttendanceListSessionClassSection =
  "get-class-sec-sess-student-attendance-list";

const getCertTemplateNames = "get-template-names";
const getCertFontNames = "get-font-names";
const getCertTemplateThumbnailFolder = "thumbnail";
const getCertFontFolder = "font-images";
const saveEventDB = "save-event";
const getEventParticipantsDB = "get-event-participants";
const updateEventParticipantsDB = "update-event-participants";
const generateCertificatesCommand = "generate-certificates";
const reviewCertificatesPath = "cert-thumbnail";
const deleteEventDB = "delete-event";
const addParticipantToDB = "add-event-participants";
const logoutRoute = "logout";
const DOMAIN_PATH = ";domain=.idigizen.com";
const getLandingPageSpecs = "get-landing-page-specs";
const getLandingPageIconImg = "landing-page-icon-img";
const saveFormDB = "save-form-db";
const getFormDB = "get-form-db";
const getFormsListDB = "get-forms-list-db";
const saveFormInstance = "save-form-instance";
const getFormInstanceList = "get-form-instances";
const getFormInstanceDetails = "get-form-instance-details";
const saveTableDB = "save-table-db";
const deleteTableDB = "delete-table-db";
const deleteTableEntryDB = "delete-table-entry-db";
const getTableDB = "get-table-db";
const getTablesListDB = "get-tables-list-db";
const getTableDataDB = "get-table-data-db";
const saveBlob = "save-blob";
const getDynamicColValuesDB = "get-dynamic-col-values";
const saveTableDataBackend = "save-table-data";
const initTimeTable = "init-time-table";
const getTimeTable = "get-time-table";
const saveTimeTable = "save-time-table";
const getTimeTableClassList = "get-time-table-class-list";
const generateReportCards = "generate-report-cards";
const getTermListDB = "get-term-list";
const getReportCardReview = "get-report-card-review";
const getReportCardThumbnail = "get-report-card-thumbnail";
const deleteEventParticiapntDB = "delete-event-participant";

const generateTimeTable = "generate-time-table";
const savePTMLog = "save-ptm-log";
const getStudentPTMLogs = "get-student-ptm-logs";
const saveHomeWork = "save-home-work";
const getHomeWorkList = "get-home-work-list";
const reportVerticals = ["Scholastic", "Co-Scholastic", "Behavior"];
const uploadFiles = "upload-files";
const careerPredictorApi = "idigizen-ai-backend";
const getCompletion = "get-completion";
const updateAccolade = "update-record";
const addSsiEcaScoreDB = "add-ssi-eca-score-db";
const getSsiCountDB = "get-ssi-count-db";
const getTableMobStructDB = "get-table-mob-db";
const saveTableMobStructDB = "save-table-mob-db";
const getEcaActivitiesDB = "get-eca-activities-db";
const getSsiListDB = "get-ssi-list-db";
const getSsiEcaScoreDB = "get-ssi-eca-score-db";
const addCheckUserDB = "add-check-user-db";
const registerZPreneurStudent = "register-zpreneur-student";
const zPreneurCourseDetails = "zPreneur-course-details";
const getCountryStateCityNGstData = "get-country-state-city-gst-data";
const updateUserDataDB = "update-user-info";
const updateSchoolDB = "update-school-info";
const checkAndSaveDomainName = "check-and-save-domain-name";
const getBhpCompanyDetailsDB = "get-bhp-company-details-db";
const getBhpTeamDetailsDB = "get-bhp-team-details-db";
const getBhpProductDetailsDB = "get-bhp-product-details-db";
const getBhpTestimonialsDetailsDB = "get-bhp-testimonials-db";

export {
  getBhpCompanyDetailsDB,
  getBhpTeamDetailsDB,
  getBhpProductDetailsDB,
  getBhpTestimonialsDetailsDB,
  checkAndSaveDomainName,
  updateSchoolDB,
  updateUserDataDB,
  addCheckUserDB,
  getSsiEcaScoreDB,
  getSsiListDB,
  getEcaActivitiesDB,
  addSsiEcaScoreDB,
  getSsiCountDB,
  getTermListDB,
  careerPredictorApi,
  saveTableDB,
  generateReportCards,
  deleteTableDB,
  deleteTableEntryDB,
  saveTableDataBackend,
  getTableDataDB,
  getTableDB,
  getTablesListDB,
  saveFormDB,
  saveBlob,
  getFormDB,
  getFormsListDB,
  getDynamicColValuesDB,
  saveFormInstance,
  getFormInstanceList,
  getFormInstanceDetails,
  saveEventDB,
  deleteEventDB,
  updateAccolade,
  getTimeTableClassList,
  reviewCertificatesPath,
  IDIGIZEN_BACKEND_URL,
  API_SECURE_KEY,
  getLandingPageIconImg,
  deleteAnecdote,
  initTimeTable,
  saveTimeTable,
  getReportCardReview,
  getTimeTable,
  getEventParticipantsDB,
  updateEventParticipantsDB,
  DOMAIN_PATH,
  userInfoRouter,
  generateCertificatesCommand,
  updateEventStatusDB,
  getLandingPageSpecs,
  getStudentAttendanceListSessionClassSection,
  subClassSecSessExamBundleRouter,
  subClassSecSessExamMarksSaveRouter,
  getBehaviorTraitsRouter,
  subClassSecSessTraitsBundleSaveRouter,
  deleteReportCardComments,
  logoutRoute,
  getCertTemplateThumbnailFolder,
  getFileThumbnailDB,
  getEventDetailsDB,
  getCertFontNames,
  getCertFontFolder,
  sqlPullStudentAccolades,
  sqlPullStudentList,
  getBehaviorTraitsPerStudentRouter,
  addParticipantToDB,
  subClassSecSessStudentTraitsBundleSaveRouter,
  getStudentListSessionClassSection,
  getSessPerStudentReport,
  reportVerticals,
  saveStudentAttendance,
  getStudentReportCardComments,
  getEventInputDB,
  getReportCardComments,
  getCertTemplateNames,
  saveReportCardComments,
  getAnecdoteList,
  getEventListDB,
  getStudentAnecdoteList,
  saveAnecdote,
  getReportCardThumbnail,
  getReportCardStudentListClassSection,
  generateTimeTable,
  savePTMLog,
  saveHomeWork,
  getHomeWorkList,
  getStudentPTMLogs,
  deleteEventParticiapntDB,
  uploadFiles,
  getCompletion,
  getTableMobStructDB,
  saveTableMobStructDB,
  getEventDetailsForBusinessPage,
  registerZPreneurStudent,
  zPreneurCourseDetails,
  getCountryStateCityNGstData,
};
