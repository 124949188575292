const HEADER_BREAKPOINT = 950;
const mobileLayoutBreakpoint = 800;
const tabLayoutBreakpoint = 1275;

const getScreenType = (screenWidth: number): "Tab" | "PC" | "Mobile" => {
  return screenWidth > tabLayoutBreakpoint
    ? "PC"
    : screenWidth > mobileLayoutBreakpoint
    ? "Tab"
    : "Mobile";
};

export {
  HEADER_BREAKPOINT,
  mobileLayoutBreakpoint,
  tabLayoutBreakpoint,
  getScreenType,
};
