import { IonPage, useIonToast, IonTitle } from "@ionic/react";
import { useLocation, useHistory } from "react-router";

import { useEffect, useRef, useState } from "react";

import axios from "axios";
import {
  IDIGIZEN_BACKEND_URL,
  API_SECURE_KEY,
  registerZPreneurStudent,
  zPreneurCourseDetails,
  getCountryStateCityNGstData,
} from "../types/constants";

import { getSessionDetails, storeSessionDetails } from "../common/cookies";
import {
  getParamsFromSearchString,
  keyExists,
} from "../common/commonFunctions";

import { useCustomToast } from "../app/hooks";

/************************************************************************
 * Name: Payment
 * Description: Intermediate page triggered to register the user from
 *              zPreneurRegistrations table, log him/her in and proceed
 *              to the payment processing for zPreneur workshop. This page
 *              will never be visible to the user.
 ************************************************************************/
const Payment: React.FC = () => {
  const [encData, setEncData] = useState<{
    access_code: string;
    merchantData: string;
    merchantId: string;
  }>({ access_code: "", merchantData: "", merchantId: "" });
  const [submitForm, setSubmitForm] = useState<boolean>(false);
  const location = useLocation();
  const history = useHistory();
  let searchParams = getParamsFromSearchString(location.search);
  const [present] = useIonToast();
  const [studentData, setStudentData] = useState<any>();
  const baseUrl = IDIGIZEN_BACKEND_URL;

  const { token, id } = getSessionDetails();
  console.log("Payment rendered");

  //Entry useEffect triggered on mount of component.
  useEffect(() => {
    //useEffect functionality
    const loginNCollectPayment = async () => {
      const studentDataL = await registerUserIdNeeded();
      if (!studentDataL) {
        console.log("Payment triggered without registration.");
        history.goBack();
        return;
      }

      //user is registered and logged in. Trigger the payment cycle.
      triggerPayment(studentDataL);
    };

    searchParams = getParamsFromSearchString(location.search);
    loginNCollectPayment();
  }, [location]);

  //useEffect to submit to ccAvenue for payment processing
  useEffect(() => {
    if (submitForm) {
      const myForm: HTMLFormElement = document.getElementById(
        "nonseamless"
      ) as HTMLFormElement;

      myForm.submit();
    }
  }, [submitForm, encData, location]);

  //Helper function to present a toast message to the user
  const presentToastLocal = (message: string) => {
    present({
      message,
      position: "middle",
      duration: 1500,
    });
  };

  /**********************************************************************
   * Name: registerUserIdNeeded
   **********************************************************************/
  const registerUserIdNeeded = async (): Promise<{}> => {
    const { email, phoneNumber } = searchParams;

    if (!email && !phoneNumber) {
      return false;
    }

    //trigger the registration request
    let res = await axios.post(
      `${baseUrl}/${registerZPreneurStudent}`,
      {},
      { params: { email, phoneNumber, Authorization: API_SECURE_KEY } }
    );

    if (res.data.status !== "OK") {
      presentToastLocal(res.data.message);
      return false;
    }

    const studentDataL = res.data.data;
    setStudentData(studentDataL);
    return studentDataL;
  }; //End registerIfNeededAndLoginUser

  /**********************************************************************
   * Name: triggerPayment
   **********************************************************************/
  const triggerPayment = async (studentDataL: any) => {
    const { id, token } = getSessionDetails();
    const params = {
      Authorization: API_SECURE_KEY,
      id,
      token,
      type: searchParams.type,
    };
    const headers = { "Content-Type": "application/json", access_token: token };
    const cfg = { params, headers };

    //get the course details
    let res = await axios.get(`${baseUrl}/${zPreneurCourseDetails}`, cfg);
    let course = res.data.data;

    console.log("Preparing purchase data studentData =", studentDataL);
    let purchaseData = await preparePurchaseData(studentDataL, course);
    if (!purchaseData) {
      return;
    }

    //trigger the request to encode purchase data for submit to ccAve.
    res = await axios.post(`${baseUrl}/purchase_details`, purchaseData, cfg);

    //set the state and trigger form submit to ccavenue
    setEncData(res.data.data.keys);
    setSubmitForm(true);
  };

  /**********************************************************************
   * Name: preparePurchaseData
   **********************************************************************/
  const preparePurchaseData = async (studentDataL: any, course: any) => {
    const cfg = {
      params: {
        Authorization: API_SECURE_KEY,
        cityId: studentDataL.cityId,
        state: studentDataL.state,
        country: studentDataL.country,
      },
    };
    const res = await axios.get(
      `${baseUrl}/${getCountryStateCityNGstData}`,
      cfg
    );
    if (res.data.status !== "OK") {
      presentToastLocal(
        `Error: Could not complete order backend error. Error =, ${res.data.error}`
      );
      return;
    }

    //calculate totalAmount and gst.
    let gstPrice = 0;
    const gstData = res.data.gst;
    const gstValues = {};
    for (const fieldName of ["igst", "cgst", "sgst", "cess"]) {
      gstValues[fieldName] = 0;
    }
    gstValues["igst"] = (
      course.publishedPrice -
      course.publishedPrice / 1.18
    ).toFixed(2);

    let totalAmount = course.publishedPrice;
    //special handling of discount for scottish
    console.log("searchParams =", searchParams);
    if (
      keyExists("isScottish", searchParams) &&
      searchParams.isScottish === "Yes"
    ) {
      totalAmount = (course.actualPrice * 0.75).toFixed(2);
    }

    /* Disabling hardcoded coupon code flow. 
    const couponCodeMap = {
      zPreneur: { early_4000: 0.2, parent_4250: 0.15 },
      BAKING: { parent_4250: 0.1333 },
    };
    //generic coupon code application
    if (searchParams.couponCode) {
      const couponCode = searchParams.couponCode.toLowerCase();
      let type = searchParams.type ? searchParams.type : "zPreneur";
      const discountPercent = couponCodeMap[type][couponCode];

      if (discountPercent) {
        totalAmount = (course.actualPrice * (1 - discountPercent)).toFixed(2);
      }
    }

    //special case for one off coupon for Nidhi
    if (
      !searchParams.type &&
      searchParams.couponCode &&
      searchParams.couponCode.toLowerCase() === "early_4000"
    ) {
      totalAmount = 4000;
    }*/
    const purchaseData = {
      userId: studentDataL.id,
      email: studentDataL.email,
      mobile: studentDataL.phoneNumber,
      name: studentDataL.firstName,
      address: searchParams.address,
      zipcode: searchParams.zipCode,
      city: { id: 1, name: searchParams.city },
      state: { id: 1, name: searchParams.state },
      country: { id: 101, name: "India" },
      course: [
        {
          courseid: course.id,
          coursetitle: course.title,
          trainerprice: course.trainerprice,
          amount: totalAmount,
          publishedprice: course.publishedPrice,
          actualPrice: course.actualPrice,
          description: course.description,
          type: course.type,
        },
      ],
      gst: gstValues,
      orderDiscount: "",
      actualAmount: totalAmount,
    };
    console.log("purchaseData =", purchaseData);
    return purchaseData;
  };

  return (
    <>
      <IonPage>
        <form
          id="nonseamless"
          name="submit"
          method="post"
          action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
        >
          <input
            type="hidden"
            id="encRequest"
            name="encRequest"
            value={encData.merchantData}
          />
          <input
            type="hidden"
            name="access_code"
            id="access_code"
            value={encData.access_code}
          />
          <IonTitle color="primary">
            Please wait, redirecting you to Payment Page...
          </IonTitle>
        </form>
      </IonPage>
    </>
  );
};

export default Payment;
