import React, { useEffect, useRef, useState } from "react";
import { useAppSelector } from "../app/hooks";
import "./styles/ErpOverview.css";
import "./styles/EventTesti.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/grid";

import "swiper/css";
import { TestimonialDataType } from "../data/testiData";
import IDigizenHeaderBandCard from "../UI/IDigizenHeaderBandCard";

const TestiCrousel: React.FC<{
  DataSet: TestimonialDataType[];
  bg: boolean;
  headerPosition?: "right" | "left";
}> = (props) => {
  let screenType: "PC" | "Tab" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });

  if (widthObj.width > 1100) screenType = "PC";
  else if (widthObj.width > 600) screenType = "Tab";
  else screenType = "Mobile";

  const [swiperRef, setSwiperRef] = useState(null);
  const [imgSlide, setImgSlide] = useState(0);

  const [swiperRefTab, setSwiperRefTab] = useState(null);
  const [imgSlideTab, setImgSlideTab] = useState(0);

  const [swiperRefPC, setSwiperRefPC] = useState(null);
  const [imgSlidePC, setImgSlidePC] = useState(0);

  console.log(props.DataSet.length);

  useEffect(() => {
    const intervalID = setInterval(() => {
      setImgSlide((val) => {
        if (val == props.DataSet.length) {
          return 0;
        } else {
          return ++val;
        }
      });
    }, 3000);

    return () => {
      clearInterval(intervalID);
    };
  }, []);

  useEffect(() => {
    const intervalIDTab = setInterval(() => {
      setImgSlideTab((val) => {
        if (val == props.DataSet.length - 1) {
          return 0;
        } else {
          const refVal = val + 1;
          return refVal;
        }
      });
    }, 3000);

    return () => {
      clearInterval(intervalIDTab);
    };
  }, []);

  useEffect(() => {
    const intervalIDPC = setInterval(() => {
      setImgSlidePC((val) => {
        if (val == props.DataSet.length - 1) {
          return 0;
        } else {
          const refVal = val + 1;
          return refVal;
        }
      });
    }, 3000);

    return () => {
      clearInterval(intervalIDPC);
    };
  }, []);

  useEffect(() => {
    if (swiperRefTab && screenType === "Tab") swiperRefTab.slideTo(imgSlideTab);
  }, [imgSlideTab]);

  useEffect(() => {
    if (swiperRef && screenType === "Mobile") swiperRef.slideTo(imgSlide);
  }, [imgSlide]);

  useEffect(() => {
    if (swiperRefPC && screenType === "PC") swiperRefPC.slideTo(imgSlidePC);
  }, [imgSlidePC]);
  const headerPosition = props.headerPosition ? props.headerPosition : "right";

  const PCLayoutJsx = (
    <>
      <IDigizenHeaderBandCard
        bgColor={props.bg}
        header={props.DataSet[0].type}
        headerPosition={headerPosition}
      >
        <div className="">
          <Swiper
            spaceBetween={40}
            initialSlide={0}
            slidesPerView={3}
            onSwiper={setSwiperRefPC}
          >
            {props.DataSet.map((items, index) => {
              return (
                <>
                  <SwiperSlide>
                    <div className="snip1390">
                      {!!items.img && (
                        <img src={items.img} className="profile" />
                      )}
                      <div className="blockquote_box">
                        <h2>
                          <b>{items.name}</b>
                        </h2>
                        <h4>{items.designation}</h4>
                        <div className="blockquote"> {items.comment} </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </div>
      </IDigizenHeaderBandCard>
    </>
  );

  const tabLayoutJsx = (
    <>
      <IDigizenHeaderBandCard
        bgColor={props.bg}
        header={props.DataSet[0].type}
        headerPosition={headerPosition}
      >
        <div className="">
          <Swiper
            spaceBetween={40}
            initialSlide={2}
            slidesPerView={2}
            onSwiper={setSwiperRefTab}
          >
            {props.DataSet.map((items, index) => {
              return (
                <>
                  <SwiperSlide>
                    <div className="snip1390">
                      {!!items.img && (
                        <img src={items.img} className="profile" />
                      )}
                      <div>
                        <h2>
                          <b>{items.name}</b>
                        </h2>
                        <h4>{items.designation}</h4>
                        <div className="blockquote"> {items.comment} </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </div>
      </IDigizenHeaderBandCard>
    </>
  );

  const mobileLayoutJsx = (
    <>
      <IDigizenHeaderBandCard
        bgColor={props.bg}
        header={props.DataSet[0].type}
        headerPosition={headerPosition}
      >
        <div className=" ">
          <Swiper
            spaceBetween={40}
            initialSlide={0}
            slidesPerView={1}
            onSwiper={setSwiperRef}
          >
            {props.DataSet.map((items, index) => {
              return (
                <>
                  <SwiperSlide>
                    <div className="snip1390">
                      {!!items.img && (
                        <img src={items.img} className="profile" />
                      )}
                      <div>
                        <h2>
                          <b>{items.name}</b>
                        </h2>
                        <h4>{items.designation}</h4>
                        <div className="blockquote"> {items.comment} </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
        </div>
      </IDigizenHeaderBandCard>
    </>
  );

  return (
    <>
      {screenType === "PC" && PCLayoutJsx}
      {screenType === "Tab" && tabLayoutJsx}
      {screenType === "Mobile" && mobileLayoutJsx}
    </>
  );
};

export default TestiCrousel;
