import "./styles/GraphicWithText.css";
import { useAppSelector } from "../app/hooks";
import { GraphicWithTextData } from "../data/LandingPageData";
import { IonButton } from "@ionic/react";

const GraphicWithText = () => {
  let screenType: "PC" | "Tab" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });
  if (widthObj.width > 1100) screenType = "PC";
  else if (widthObj.width > 600) screenType = "Tab";
  else screenType = "Mobile";
  const cardbg = [" #c8e4fc", " #f0e4fc", "#c8e4fc"];
  const gotoIdigicator = () => {
    window.location.assign("https://idigicator.idigizen.com");
  };

  const PCLayoutJsx = (
    <>
      <div className="graphic_with_text-container">
        <div className="graphic_with_text-haeding">
          <h1> {GraphicWithTextData.heading.title}</h1>
          <h2> {GraphicWithTextData.heading.subtitile} </h2>
          <div style={{ marginTop: "20px" }}></div>
          <IonButton color={"secondary"} shape="round" onClick={gotoIdigicator}>
            {GraphicWithTextData.heading.cta.text}
          </IonButton>
        </div>

        {GraphicWithTextData.data.map((item, index) => {
          let FlexDirection;
          FlexDirection = null;

          if (index % 2 === 0) {
            FlexDirection = "row-reverse";
          } else {
            FlexDirection = "row";
          }
          return (
            <>
              <div
                className="graphic_with_text-center"
                style={{ flexDirection: FlexDirection }}
              >
                <div className="graphic_with_text-left">
                  <div className="graphic_with_text-img">
                    <div
                      className="graphic_with_text-img-bg"
                      style={{ backgroundColor: cardbg[index] }}
                    >
                      <img src={item.imagepath} alt="" />
                    </div>
                  </div>
                </div>
                <div className="graphic_with_text-right">
                  <h1>{item.text.title}</h1>
                  <ul>
                    {item.text.points.map((items) => {
                      return (
                        <>
                          <li>{items}</li>
                        </>
                      );
                    })}
                  </ul>
                  <IonButton
                    onClick={gotoIdigicator}
                    shape="round"
                    color={"secondary"}
                  >
                    {item.cta.text}
                  </IonButton>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );

  const tabLayoutJsx = (
    <>
      <div className="graphic_with_text-container">
        <div className="graphic_with_text-haeding">
          <h1> {GraphicWithTextData.heading.title}</h1>
          <h2> {GraphicWithTextData.heading.subtitile} </h2>
          <div style={{ marginTop: "20px" }}></div>
          <IonButton color={"secondary"} shape="round" onClick={gotoIdigicator}>
            {GraphicWithTextData.heading.cta.text}
          </IonButton>
        </div>
        {GraphicWithTextData.data.map((item, index) => {
          let FlexDirection;
          FlexDirection = null;

          if (index % 2 === 0) {
            FlexDirection = "row-reverse";
          } else {
            FlexDirection = "row";
          }
          return (
            <>
              <div
                className="graphic_with_text-center"
                style={{ flexDirection: FlexDirection }}
              >
                <div className="graphic_with_text-left">
                  <div className="graphic_with_text-img">
                    <div
                      className="graphic_with_text-img-bg"
                      style={{ backgroundColor: cardbg[index] }}
                    >
                      <img src={item.imagepath} alt="" />
                    </div>
                  </div>
                </div>
                <div className="graphic_with_text-right">
                  <h1>{item.text.title}</h1>
                  <ul>
                    {item.text.points.map((items) => {
                      return (
                        <>
                          <li>{items}</li>
                        </>
                      );
                    })}
                  </ul>
                  <IonButton
                    onClick={gotoIdigicator}
                    shape="round"
                    color={"secondary"}
                  >
                    {item.cta.text}
                  </IonButton>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </>
  );

  const mobileLayoutJsx = (
    <>
      <div className="graphic_with_text-container">
        <div className="graphic_with_text-haeding">
          <h1> {GraphicWithTextData.heading.title}</h1>
          <h2> {GraphicWithTextData.heading.subtitile} </h2>
          <div style={{ marginTop: "20px" }}></div>
          <IonButton color={"secondary"} shape="round" onClick={gotoIdigicator}>
            {GraphicWithTextData.heading.cta.text}
          </IonButton>
        </div>
        {GraphicWithTextData.data.map((item, index) => {
          let FlexDirection;
          FlexDirection = "column-reverse";

          return (
            <>
              <div
                className="graphic_with_text-center"
                style={{ flexDirection: FlexDirection }}
              >
                <div className="graphic_with_text-left">
                  <div className="graphic_with_text-img">
                    <div
                      className="graphic_with_text-img-bg"
                      style={{ backgroundColor: cardbg[index] }}
                    >
                      <img src={item.imagepath} alt="" />
                    </div>
                  </div>
                </div>
                <div className="graphic_with_text-right">
                  <h1>{item.text.title}</h1>
                  <ul>
                    {item.text.points.map((items) => {
                      return (
                        <>
                          <li>{items}</li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <IonButton
                  onClick={gotoIdigicator}
                  shape="round"
                  color={"secondary"}
                >
                  {item.cta.text}
                </IonButton>
              </div>
            </>
          );
        })}
      </div>
    </>
  );

  return (
    <>
      {screenType === "PC" && PCLayoutJsx}
      {screenType === "Tab" && tabLayoutJsx}
      {screenType === "Mobile" && mobileLayoutJsx}
    </>
  );

  return (
    <>
      {/* <div className="graphic_with_text-container">
        {GraphicWithTextData.data.map((item, index) => {
          let FlexDirection;
          FlexDirection = null;

          if (index % 2 === 0) {
            FlexDirection = "row-reverse";
          } else {
            FlexDirection = "row";
          }
          return (
            <>
              <div
                className="graphic_with_text-center"
                style={{ flexDirection: FlexDirection }}
              >
                <div className="graphic_with_text-left">
                  <div className="graphic_with_text-img">
                    <img src={item.imagepath} alt="" />
                  </div>
                </div>
                <div className="graphic_with_text-right">
                  <h1>{item.text.title}</h1>
                  <ul>
                    {item.text.points.map((items) => {
                      return (
                        <>
                          <li>{items}</li>
                        </>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </>
          );
        })}
      </div> */}
    </>
  );
};

export default GraphicWithText;
