import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { open: boolean } = { open: false };

const formModalOpenSlice = createSlice({
  name: "form-modal-open-slice",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<{ open: boolean }>) => {
      console.log("Called open modal", action, state);
      state.open = action.payload.open;
    },
  },
});

export const formModalOpenActions = formModalOpenSlice.actions;
export default formModalOpenSlice;
