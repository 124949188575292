import React from "react";
import { useAppSelector } from "../app/hooks";
import { getScreenType } from "../data/breakpoints";

const IDigizenImgCard: React.FC<{
  img: { path: string; description: string };
  text: string;
  fullHeight?: boolean;
  header?: string;
}> = (props) => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);

  return (
    <div
      className={`idigizen-solution-card ${
        screenType === "PC" || screenType === "Tab"
          ? "idigizen-solution-card-pc"
          : "idigizen-solution-card-mob"
      } ${props.fullHeight ? "idigizen-solution-card-full-height" : ""}`}
    >
      {!!props.header && (
        <div
          className={`${
            screenType === "PC"
              ? "idigizen-solution-card-text"
              : "idigizen-solution-card-text-mob"
          }`}
          style={{
            textAlign: "left",
            color: "var(--ion-color-banner-navy-blue)",
            paddingTop: "0.25em",
            paddingLeft: ".25em",
            marginLeft: "0.75em",
            //   borderBottom: "2px solid var(--ion-color-secondary)",
          }}
        >
          {props.header}
        </div>
      )}
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          className="idigizen-solution-card-img"
          src={props.img.path}
          alt={props.img.description}
        />
        <div
          className={`${
            screenType === "PC"
              ? "idigizen-solution-card-text"
              : "idigizen-solution-card-text-mob"
          }`}
          style={{ justifyContent: "left", textAlign: "left" }}
        >
          {props.text}
        </div>
      </div>
    </div>
  );
};

export default IDigizenImgCard;
