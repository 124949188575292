import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonFab,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonModal,
  IonPage,
  IonPopover,
  IonRoute,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../app/hooks";
import Footer from "../components/Footer";
import Header from "../components/Header";
import {
  appBulletText,
  iDigizenSolCardSteps,
  iDigizenSolutionFlashCards,
} from "../data/data";
import SubHeader from "../UI/SubHeader";
import "./styles/IDigizenSolution.css";
import {
  API_SECURE_KEY,
  IDIGICATOR_URL,
  SCHOOLREG_FORM_ID,
} from "../data/constants";
import { close } from "ionicons/icons";
import AboutUs from "../components/AboutUs";
import {
  getScreenType,
  mobileLayoutBreakpoint,
  tabLayoutBreakpoint,
} from "../data/breakpoints";
import WorkForPassionBanner from "../components/WorkForPassionBanner";
import IDigizenImgBand from "../components/IDigizenImgBand";
import IDigizenImgCard from "../components/IDigizenImgCard";
import IDigizenFeaturesCard from "../components/IDigizenFeaturesCard";
import { useHistory } from "react-router";
import TheTeam from "../components/TheTeam";
import IDigizenPopup from "../components/IDigizenPopup";
import IDigizenHeaderBandCard from "../UI/IDigizenHeaderBandCard";

const IDigizenSolution: React.FC = () => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);
  const history = useHistory();
  const goToRegistrationForm = () => {
    window.location.assign(`${IDIGICATOR_URL}`);
  };
  const [openPromo, setOpenPromo] = useState(true);

  useEffect(() => {
    console.log("Trigger use effect");
    setOpenPromo(true);
  }, []);
  const titleCss = screenType === "PC" ? "izp-title" : "izp-title-mob";

  const [openPdf, setOpenPdf] = useState<{
    title: string;
    open: boolean;
    path: string;
  }>({ open: false, path: "", title: "" });
  const pdfDisplayJsx = (
    <IonModal
      isOpen={openPdf.open}
      onDidDismiss={() => {
        setOpenPdf({ open: false, path: "", title: "" });
      }}
      className="idigizen-sol-pdf-viewer-modal"
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{openPdf.title}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              color={"danger"}
              fill="clear"
              onClick={() => {
                setOpenPdf({ open: false, path: "", title: "" });
              }}
            >
              <IonIcon icon={close} /> Close
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <iframe src={openPdf.path} height={"100%"} width={"100%"} />
      </IonContent>
    </IonModal>
  );

  return (
    <>
      <IonPage>
        {pdfDisplayJsx}
        <IDigizenPopup openPromo={openPromo} setOpenPromo={setOpenPromo} />
        <Header
          navref={undefined}
          actionButtonName="Register Now!"
          onActionButtonClick={goToRegistrationForm}
          buttons={[
            {
              name: "Home",
              onClick: () => {
                history.push("/");
              },
            },
            {
              name: "iZpreneur",
              onClick: () => {
                history.push("/izpreneur");
              },
            },
            {
              name: "platform",
              onClick: () => {
                history.push("/storeslanding");
              },
            },
            // {
            //   name: "School",
            //   onClick: () => {
            //     history.push("/erp");
            //   },
            // },
          ]}
        />
        <IonContent className="idigizen-solution-content" id="popover-id">
          {/* <SubHeader text="Hello" enableStickyScroll={true} /> */}
          {/* {(screenType === "PC" || screenType === "Tab") && homePageBannerPCJsx}
          {screenType === "Mobile" && homePageBannerMobJsx} */}
          <WorkForPassionBanner
            kidSubheader="Build your"
            kidHeader="Digital Portfolio..."
            adultSubheader="...discover insights into"
            adultHeader="your Learning Journey"
            hashtag="Work for Passion"
            buttonName="GET STARTED"
            onButtonClickLink={IDIGICATOR_URL}
          />
          <div className="izp-overview">
            <div className={titleCss}>
              iProfile <span className="overview_dash"> - </span>{" "}
              <span className="subTitleTransforming">
                {" "}
                Your Holistic Digital Portfolio
              </span>{" "}
            </div>
            <div style={{ fontSize: "large" }}>
              <p>
                Learning, a constant human endeavor, is a fun filled journey
                marked with goals and celebrations. Certificates, medals and
                trophies collected in this learning journey are a testament to
                our accomplishments.
              </p>
              <p>
                Be it for a kid or a college student or a successful
                professional,
                <b>
                  it is paramount that all the accolades and achievements are
                  available for easy access and verification.
                </b>{" "}
                This is not just limited to college degrees or board
                examinations, every participation certificate, every well-done
                card and every appreciation email is an essential piece to help
                stitch a Holistic learner profile.
              </p>
              <p>
                <b style={{ color: "var(--ion-color-secondary)" }}>iProfile</b>{" "}
                with a mission, “Log It to Do It”, is the starting point for
                every individual to record and safe-keep achievements. It helps
                everyone map their learning journey from early years to on-going
                learning and growth that spans our entire life.
              </p>
              <p>
                iProfile is the solution to all the scrambling that happens when
                we need to find a range of medals, certificates, school
                achievement and other activity records, for a college
                application or a job interview or for moving up the corporate
                ladder.{" "}
                <b>
                  iProfile is your personal verified digital profile
                  highlighting your development and growth.
                </b>
              </p>
              <p>
                iProfile also enables insights into a student's learning journey
                based on the records.{" "}
                <b>
                  The insights are invaluable to understand the strengths and
                  interests areas of an individual which become the pillars for
                  career guidance and further development.
                </b>
              </p>
            </div>
          </div>
          <div className="idigizen-solution-cards">
            <IonGrid>
              <IonRow>
                {iDigizenSolCardSteps.map((el) => {
                  return (
                    <IonCol
                      size={
                        screenType === "PC"
                          ? "3"
                          : screenType === "Tab"
                          ? "6"
                          : "12"
                      }
                    >
                      <IDigizenImgCard img={el.img} text={el.text} />
                    </IonCol>
                  );
                })}
              </IonRow>
            </IonGrid>
          </div>
          {/* {iDigizenSolutionFlashCards
            .sort((a, b) => a.position - b.position)
            .map((el, idx) => {
              return (
                <IDigizenImgBand
                  bgColor={idx % 2 == 0 ? true : false}
                  imgPosition={idx % 2 == 0 ? "left" : "right"}
                  data={{
                    img: el.img,
                    heading: el.header,
                    text: el.text,
                  }}
                  onButtonClickLink={IDIGICATOR_URL}
                />
              );
            })} */}
          <IDigizenFeaturesCard
            headerPC={["Your", "Digital", "Portfolio", "Secure", "Forever"]}
            headerMob={["Your Digital Portfolio", "Secure Forever"]}
            img={{
              path: "assets/images/idigi_sol.png",
              text: "iDigizen career recommendation/ counselling app",
            }}
            bulletText={appBulletText}
          />
          <AboutUs />
          <IDigizenImgBand
            bgColor={true}
            imgPosition={"left"}
            data={{
              img: { path: "assets/images/barinfeed.png", description: "" },
              heading:
                "iDigizen featured in Brainfeed - Happening ED-Tech Companies",
              text: [
                '"Do what you love and success will follow, so goes the saying. When you work in the field you love, you automatically work with passion resulting in excellence.',
                "The problem has been the lack of systematic guidance to channelize interests into fulfilling careers.",
                "This gap has now been addressed with iDigizen’s iProfile, an open system for schools, parents, students, external test and examination bodies like Olympiads.",
                'It enables continuous building and cataloging of learning records and achievements..."',
              ],
            }}
            buttonName="Read Article"
            onButtonClick={() => {
              setOpenPdf({
                open: true,
                path: "https://asset.idigizen.com/files/upload/download/marketing/Brainfeed-June-2023-extracted.pdf",
                title: "Brainfeed - Happening Ed-Tech Start-ups",
              });
            }}
          />
          <IDigizenHeaderBandCard
            bgColor={false}
            header="EdCafe - A podcast on iDigizen's vision on Holistic Education"
            headerPosition="left"
          >
            <div
              style={{
                display: "flex",
                flexDirection: screenType === "Mobile" ? "column" : "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                className="idigizen-solution-pie-text-mob"
                style={{ width: screenType === "Mobile" ? "100%" : "70%" }}
              >
                <p>
                  In this insightful interview session with Mr. Jishnu De,
                  Co-Founder at iDigizen, to discuss an amalgamation of
                  education and technology, which focuses on the critical topic
                  of Non Academic Education and Technology and the importance of
                  upskilling students' skills.
                </p>
                <p>
                  In this Interview session we would be discussing about the
                  concept of iDigizen, to work for passion. iDigizen's vision is
                  enable every student to follow their passion into a long
                  lasting fruitful career.
                </p>
                <p>
                  Backed with technology, iDigizen's iProfile aims to solve this
                  problem by building a digital portfolio for every child and
                  deriving insights from the learning journey to guide forward
                  in the career decisions and growth.
                </p>
              </div>
              <div
                style={{ marginLeft: screenType === "Mobile" ? "0" : "20px" }}
              >
                <iframe
                  width={screenType === "Mobile" ? "350" : "444"}
                  height={screenType === "Mobile" ? "197" : "250"}
                  src="https://www.youtube.com/embed/ooZntiheG30"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                ></iframe>
              </div>
            </div>
          </IDigizenHeaderBandCard>
          <TheTeam noText={true} />
          <Footer />
        </IonContent>
      </IonPage>
    </>
  );
};

export default IDigizenSolution;
