import { IonButton, IonCol, IonGrid, IonImg, IonRow } from "@ionic/react";
import { sign } from "crypto";
import React, { useEffect, useRef } from "react";
import { useAppSelector } from "../app/hooks";
import { NavLinkRefType, stakeholderData } from "../data/data";
import Card from "../UI/Card";
import SubHeader from "../UI/SubHeader";
import FormButton from "./FormButton";
import StakeHolderCard from "./StakeHolderCard";
import "./styles/StakeholderSection.css";

const StakeholderSection: React.FC<{ navRef: NavLinkRefType }> = (props) => {
  const ref = useRef();
  let screenType: "PC" | "Tab" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });

  if (widthObj.width > 1100) screenType = "PC";
  else if (widthObj.width > 600) screenType = "Tab";
  else screenType = "Mobile";

  useEffect(() => {
    if (ref) props.navRef.ref.current[props.navRef.id] = ref;
  }, [ref]);

  const size = screenType === "PC" ? "5" : screenType === "Tab" ? "9" : "12";
  const headerSticky = screenType !== "PC" ? true : false;

  return (
    <div className="stakeholder-header-top" ref={ref}>
      <div id="stakeholder-header-top-id" className="navlink-class"></div>
      <SubHeader
        text="Holistic Education Ecosystem"
        enableStickyScroll={headerSticky}
      />
      <IonGrid>
        <IonRow>
          <IonCol
            size={size}
            offset={
              screenType === "PC" ? "0.75" : screenType === "Tab" ? "1.5" : ""
            }
          >
            <StakeHolderCard data={stakeholderData[0]} />
          </IonCol>
          <IonCol
            size={size}
            offset={
              screenType === "PC" ? "0.5" : screenType === "Tab" ? "1.5" : ""
            }
          >
            <StakeHolderCard data={stakeholderData[1]} />
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol
            size={size}
            offset={
              screenType === "PC" ? "0.75" : screenType === "Tab" ? "1.5" : ""
            }
          >
            <StakeHolderCard data={stakeholderData[2]} />
          </IonCol>
          <IonCol
            size={size}
            offset={
              screenType === "PC" ? "0.5" : screenType === "Tab" ? "1.5" : ""
            }
          >
            <StakeHolderCard data={stakeholderData[3]} />
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className="stakeholder-section-button">
        <FormButton name="Get Started" />
      </div>
    </div>
  );
};

export default StakeholderSection;
