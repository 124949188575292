import React, { useState } from "react";
import "./styles/PricingTable.css";
import { IonCol, IonGrid, IonRow, IonIcon } from "@ionic/react";
import { checkmarkCircleSharp, closeCircleSharp } from "ionicons/icons";
import { Icon } from "ionicons/dist/types/components/icon/icon";
import { useAppSelector } from "../app/hooks";
import { getScreenType } from "../data/breakpoints";
import { IonButton } from "@ionic/react";

const PricingTable = () => {
  const screenWidth = useAppSelector((state) => state.screenWidth).width;
  const screenType = getScreenType(screenWidth);

  const pricingData = [
    {
      Feature: "Digital catalogue Upto 250 Products",
      Free: true,
      Paid: true,
    },
    {
      Feature:
        "Online Store Maneger to Hangle online store and manage all technical operations",
      Free: true,
      Paid: true,
    },
    {
      Feature: "Tech Support ",
      Free: false,
      Paid: true,
    },
    {
      Feature: "Feature 4",
      Free: true,
      Paid: true,
    },
  ];

  return (
    <div className="pricing_container">
      <h1>Explore Our Subscription Models</h1>
      <p>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Sed et
        inventore.
      </p>

      <IonGrid>
        <IonRow class="Header_row_business_listing ion-justify-content-center">
          <IonCol
            size={screenType === "PC" ? "3" : "6"}
            color="primary"
          ></IonCol>
          <IonCol
            size={screenType === "PC" ? "2" : "3"}
            color="primary"
            className="pricing_table_heading"
          >
            Free
            <p>₹ 0 / Forever</p>
            <a href=""> Enroll</a>
          </IonCol>
          <IonCol
            size={screenType === "PC" ? "2" : "3"}
            color="primary"
            className="pricing_table_heading"
          >
            Paid
            <p>₹ 250 / Month</p>
            <a href=""> Buy</a>
          </IonCol>
        </IonRow>
        {pricingData.map((el) => {
          return (
            <>
              <IonRow class="ion-justify-content-center row_business_listing">
                <IonCol
                  size={screenType === "PC" ? "3" : "6"}
                  color="primary"
                  className="pricing_table_elem"
                >
                  {el.Feature}
                </IonCol>
                <IonCol
                  size={screenType === "PC" ? "2" : "3"}
                  color="primary"
                  className="pricing_table_ticks"
                >
                  {!!el.Free && (
                    <IonIcon
                      size="large"
                      className="green_icon"
                      icon={checkmarkCircleSharp}
                    />
                  )}
                  {!el.Free && (
                    <IonIcon
                      size="large"
                      color="secondary"
                      icon={closeCircleSharp}
                    />
                  )}
                </IonCol>
                <IonCol
                  size={screenType === "PC" ? "2" : "3"}
                  color="primary"
                  className="pricing_table_ticks"
                >
                  {!!el.Paid && (
                    <IonIcon
                      size="large"
                      className="green_icon"
                      icon={checkmarkCircleSharp}
                    />
                  )}
                  {!el.Paid && (
                    <IonIcon
                      size="large"
                      color="secondary"
                      icon={closeCircleSharp}
                    />
                  )}
                </IonCol>
              </IonRow>
            </>
          );
        })}
      </IonGrid>
    </div>
  );
};

export default PricingTable;
