import "./styles/StoreListing.css";
import {
  IonItem,
  IonLabel,
  IonButton,
  IonIcon,
  IonGrid,
  IonCol,
  IonRow,
} from "@ionic/react";
import { useAppSelector } from "../app/hooks";
import { star } from "ionicons/icons";
import { StoreData } from "../data/testiData";
import IDigizenHeaderBandCard from "../UI/IDigizenHeaderBandCard";

const StoreListing = (props) => {
  let screenType: "PC" | "Tab" | "Mobile" = "PC";
  const widthObj: { width: number } = useAppSelector((state) => {
    return state.screenWidth;
  });

  if (widthObj.width > 1100) screenType = "PC";
  else if (widthObj.width > 600) screenType = "Tab";
  else screenType = "Mobile";

  const PCLayoutJsx = (
    <>
      <IDigizenHeaderBandCard bgColor={false} header=" " headerPosition="right">
        <div className="storelisting_backdrop">
          <div className="storelisting_container">
            <IonGrid>
              <IonRow class="ion-justify-content-around">
                {StoreData.map((item) => {
                  const link = `https://${item.domaine}.idigizen.com/`;

                  return (
                    <>
                      <IonCol size="5" className="storelisting_card">
                        <div className="top">
                          <div className="top_head">
                            <img className="profile" src={item.logo} alt="" />
                            <div className="storelisting_card-heading-box">
                              <h3>{item.name}</h3>
                              <p>{item.tagline}</p>
                            </div>
                          </div>
                          <div className="top_body">
                            <p>{item.discription}</p>
                          </div>
                        </div>
                        <div className="bottom">
                          <a href={link}>
                            <IonButton
                              shape="round"
                              className="listing_btn"
                              color={"tertiary"}
                            >
                              VISIT STORE
                            </IonButton>
                          </a>
                        </div>
                      </IonCol>
                    </>
                  );
                })}
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IDigizenHeaderBandCard>
    </>
  );

  const tabLayoutJsx = (
    <>
      <div className="storelisting_backdrop">
        <div className="storelisting_container">
          <IonGrid>
            <IonRow class="ion-justify-content-around">
              {StoreData.map((item) => {
                const link = `https://${item.domaine}.idigizen.com/`;

                return (
                  <>
                    <IonCol size="8" className="storelisting_card">
                      <div className="top">
                        <div className="top_head">
                          <img className="profile" src={item.logo} alt="" />
                          <div className="storelisting_card-heading-box">
                            <h3>{item.name}</h3>
                            <p>{item.tagline}</p>
                          </div>
                        </div>
                        <div className="top_body">
                          <p>{item.discription}</p>
                        </div>
                      </div>
                      <div className="bottom">
                        <h5>About Us</h5>
                        <a href={link}>
                          <IonButton
                            shape="round"
                            className="listing_btn"
                            color={"tertiary"}
                          >
                            VISIT STORE
                          </IonButton>
                        </a>
                      </div>
                    </IonCol>
                  </>
                );
              })}
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </>
  );

  const mobileLayoutJsx = (
    <>
      <div className="storelisting_backdrop">
        <div className="storelisting_container">
          <IonGrid>
            <IonRow class="ion-justify-content-around">
              {StoreData.map((item) => {
                const link = `https://${item.domaine}.idigizen.com/`;
                return (
                  <>
                    <IonCol size="12" className="storelisting_card">
                      <div className="top">
                        <img className="profile" src={item.logo} alt="" />

                        <div className="storelisting_card-heading-box">
                          <h3 style={{ textAlign: "center", padding: "0px" }}>
                            {item.name}
                          </h3>
                          <p style={{ textAlign: "center", padding: "0px" }}>
                            {item.tagline}
                          </p>
                        </div>

                        <p>{item.discription}</p>
                      </div>
                      <div className="bottom">
                        <a href={link}>
                          <IonButton
                            shape="round"
                            className="listing_btn"
                            color={"tertiary"}
                          >
                            VISIT STORE
                          </IonButton>
                        </a>
                      </div>
                    </IonCol>
                  </>
                );
              })}
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </>
  );

  return (
    <>
      {screenType === "PC" && PCLayoutJsx}
      {screenType === "Tab" && tabLayoutJsx}
      {screenType === "Mobile" && mobileLayoutJsx}
    </>
  );
};

export default StoreListing;
